import React, { useState, useEffect } from "react";
import { Avatar, Body1, Body1Strong, makeStyles, MenuButton, Popover, PopoverProps, PopoverSurface, PopoverTrigger, shorthands, Subtitle2, tokens, Link, Skeleton, SkeletonItem } from "@fluentui/react-components";
import { useMsal } from "@azure/msal-react";
import TenantPicker from "./TenantPicker";
import IGraphUserProfile from "interfaces/graphUserProfile";
import { fetchUserData, signIn, getAccessToken } from "../../auth/index";

const useStyles = makeStyles({
  button: {
    display: "flex",
    alignItems: "center",
    height: "48px",
    width: "48px",
    minWidth: "48px",
    ...shorthands.borderRadius(0),
    ...shorthands.padding(0),
    ...shorthands.margin(0, tokens.spacingHorizontalS, 0, 0),
    transitionProperty: "background-color",
    transitionDuration: tokens.durationFast,

    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, .15)",
    },
  },
  popoverContainer: {
    display: "flex",
    flexDirection: "column",
    rowGap: tokens.spacingVerticalM,
    minWidth: "300px",
  },
  popoverHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  popoverBody: {
    display: "flex",
    columnGap: tokens.spacingHorizontalM,
  },
  popoverDetails: {
    display: "flex",
    flexDirection: "column",
  },
});

interface ProfileCalloutProps {
  visible: boolean;
  onDismiss: () => void;
  targetRef: React.RefObject<HTMLDivElement>;
  profile: IGraphUserProfile | undefined;
}

function ProfileCallout(props: ProfileCalloutProps) {
  const styles = useStyles();
  const { instance, accounts, inProgress } = useMsal();
  const [open, setOpen] = useState(false);
  const [userDetails, setUserDetails] = useState<IGraphUserProfile | null>(null);
  const [photoUrl, setPhotoUrl] = useState<string | null>(null);
  const [showTenantPicker, setShowTenantPicker] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const handleOpenChange: PopoverProps["onOpenChange"] = (e, data) => setOpen(data.open || false);

  const handleTenantPickerClick = () => {
    setShowTenantPicker(true);
    setOpen(false);
  };

  useEffect(() => {
    const initializeUser = async () => {
      if (accounts.length === 0) {
        await signIn(inProgress);
      }
      const accessToken = await getAccessToken(inProgress);
      if (accessToken) {
        const userData = await fetchUserData(accessToken);
        if (userData) {
          setUserDetails(userData.user);
          setPhotoUrl(userData.photo);
        } else {
          setError("Failed to acquire access token.");
        }
      }
      setLoading(false);
    };

    initializeUser();
  }, [inProgress]);

  return (
    <div>
      <Popover positioning="below-end" open={open} onOpenChange={handleOpenChange}>
        <PopoverTrigger>
          <MenuButton
            appearance="transparent"
            icon={<Avatar image={{ src: photoUrl ?? "" }} />}
            className={styles.button}
          ></MenuButton>
        </PopoverTrigger>

        <PopoverSurface>
          {loading ? (
            <Skeleton>
              <div className={styles.popoverContainer}>
                <div className={styles.popoverHeader}>
                  <SkeletonItem shape="rectangle" size={16} style={{ width: "30%" }} />
                </div>
                <div className={styles.popoverBody}>
                  <SkeletonItem shape="circle" size={64} />
                  <SkeletonItem shape="rectangle" size={64} style={{ width: "80%" }} />
                </div>
              </div>
            </Skeleton>
          ) : error ? (
            <div className={styles.popoverContainer}>
              <Body1>{error}</Body1>
            </div>
          ) : (
            <>
              <div className={styles.popoverContainer}>
                <div className={styles.popoverHeader}>
                  {userDetails && <Body1Strong>{userDetails.officeLocation}</Body1Strong>}
                </div>
                <div className={styles.popoverBody}>
                  <Avatar image={{ src: photoUrl ?? "" }} size={64} color="colorful" />
                  <div className={styles.popoverDetails}>
                    <Subtitle2>{userDetails?.displayName}</Subtitle2>
                    <Body1>{userDetails?.mail}</Body1>
                    <Link as="button" onClick={handleTenantPickerClick}>
                      Wisselen van tenant
                    </Link>
                  </div>
                </div>
              </div>
            </>
          )}
        </PopoverSurface>
      </Popover>

      <TenantPicker shouldOpen={showTenantPicker} onClose={() => setShowTenantPicker(false)} />
    </div>
  );
}

export default ProfileCallout;
