import filterConfig from "config/FilterConfig";
import Filter from "./CollapsibleFilter";
import { makeStyles } from "@fluentui/react-components";

const useStyles = makeStyles({
  sideLeft: {
    backgroundColor: "#ffffff",
    paddingLeft: "20px",
    paddingRight: "15px"
  }
});

const KlantFilters = ({ getHouseCountForFilter, ...props }) => {
  const styles = useStyles();
  const klantFilters = filterConfig.filter(
    (filter) => filter.filterCategory === "klant"
  );

  // Line 11 and 101 must be uncommented when we have real data for account

  // const accountFilters = filterConfig.filter(filter => filter.filterCategory === 'account');

  const renderFilters = (filters, heading) => (
    <div className={styles.sideLeft}>
      {/* This section must be removed when we have real data for account */}
      {/* ------------------------------------------------- */}
      <h2 className="heading">Account</h2>
      <Filter
        title="Klant relatie"
        getHouseCount={getHouseCountForFilter}
        label="srt"
        options={[
          { id: 1, name: "Huurder" },
          { id: 2, name: "Zakelijk huurder" },
          { id: 3, name: "Zoeker" },
          { id: 4, name: "Kandidaat" },
          { id: 5, name: "Ruiler" },
        ].map((option) => option.name)}
        selectedItems={[]}
        handleCheckboxChange={function (option: string): void {
          throw new Error("Function not implemented.");
        }}
      />
      <Filter
        title="Zaak"
        getHouseCount={getHouseCountForFilter}
        label="srt"
        options={[]}
        selectedItems={[]}
        handleCheckboxChange={function (option: string): void {
          throw new Error("Function not implemented.");
        }}
      />

      <Filter
        title="Inlog"
        options={[
          { id: 1, name: "Ja" },
          { id: 2, name: "Nee" },
        ].map((option) => option.name)}
        getHouseCount={getHouseCountForFilter}
        label="w"
        selectedItems={[]}
        handleCheckboxChange={function (option: string): void {
          throw new Error("Function not implemented.");
        }}
      />

      <Filter
        title="Communicatie voorkeur"
        options={[
          { id: 1, name: "E-mail" },
          { id: 2, name: "Post" },
          { id: 3, name: "Berichtenbox" },
        ].map((option) => option.name)}
        getHouseCount={getHouseCountForFilter}
        label="b"
        selectedItems={[]}
        handleCheckboxChange={function (option: string): void {
          throw new Error("Function not implemented.");
        }}
      />
      {/* -------------------------------------------------  */}

      <h2 className="heading">{heading}</h2>
      {filters.map((filter) => {
        const { filterKey, stateKey, title, handler, filteredKey } = filter;

        const options = props[filteredKey] || [];
        const selectedItems = props[stateKey] || [];
        const handleCheckboxChange = handler ? props[handler] : () => {};

        return (
          <Filter
            key={filterKey}
            title={title}
            options={options}
            selectedItems={selectedItems}
            handleCheckboxChange={handleCheckboxChange}
            getHouseCount={getHouseCountForFilter}
            label={filterKey}
          />
        );
      })}
    </div>
  );

  return (
    <>
      {/* {renderFilters(accountFilters, "Account")} */}
      {renderFilters(klantFilters, "Zaken")}
    </>
  );
};

export default KlantFilters;
