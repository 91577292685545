import { makeStyles, tokens } from "@fluentui/react-components";

export const useStyles = makeStyles({
  filterLabel: {
    margin: "10px",
    display: "flex",
    alignItems: "center",
    position: "relative",
  },

  filterLabelInput: {
    marginRight: "5px",
    cursor: "pointer",
  },

  houseCount: {
    marginLeft: "auto",
  },
  container: {
    display: "flex",
    overflow: "hidden",
    margin: 0,
    maxHeight: "calc(100vh - 50px)",
  },
  parentContainer: {
    display: "flex",
    flexDirection: "column",
    height: "95vh",
    width: "320px",
    boxShadow: "0 0 10px 2px rgb(0 0 0 / 20%)",
    zIndex: 1000,
  },
  parentContainerContent: {
    overflowY: "auto",
    overflowX: "hidden",
    flexGrow: 1,
  },
  footer: {
    marginTop: "10px",
    position: "sticky",
    bottom: 0,
    fontSize: "16px",
    backgroundColor: tokens.colorBrandForeground1,
    color: tokens.colorBrandBackground2,
    padding: "15px 0 15px 0",
    textAlign: "center",
    border: "solid 15px white",
    boxShadow: "0px -1px 2px 0px rgba(0, 0, 0, 0.132), 0px -1px 7px 0px rgba(0, 0, 0, 0.108)",
  },
  sideRight: {
    flex: 1,
  },
  itemDetail: {
    position: "fixed",
    top: "83vh",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#ffffff",
    border: "1px solid #ffffff",
    maxWidth: "760px",
    maxHeight: "240px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    zIndex: 1001,
  },
  mapContainer: {
    height: "95vh",
    width: "100%",
  },
  epLabels: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  searchBox: {
    position: "fixed",
    zIndex: 1000,
    marginLeft: "340px",
    marginTop: "12px",
    width: "300px",
  },
});