import Filter from "./CollapsibleFilter";
import { makeStyles} from "@fluentui/react-components";

const useStyles = makeStyles({
  sideLeft: {
    backgroundColor: "#ffffff",
    paddingLeft: "20px",
    paddingRight: "15px"
  }
});

function MarketFilters(props) {
  const styles = useStyles();
  const {
    selectedEenheidDetailsoort,
    handleEenheidDetailsoortCheckboxChange,
    getHouseCountForFilter,
    selectedWoonplaats,
    handleWoonplaatsCheckboxChange,
    selectedCBSBuurt,
    handleCBSBuurtCheckboxChange,
  } = props;

  return (
    <div className={styles.sideLeft}>
      <h2 className="heading">Markt</h2>
      <Filter
        title="Acceptiegraad"
        selectedItems={selectedEenheidDetailsoort}
        handleCheckboxChange={handleEenheidDetailsoortCheckboxChange}
        getHouseCount={getHouseCountForFilter}
        label="srt"
        options={[
          { id: 1, name: "1" },
          { id: 2, name: "2" },
          { id: 3, name: "3" },
          { id: 4, name: "> 3" },
        ].map((option) => option.name)}
      />

      <Filter
        title="Populariteit"
        options={[
          { id: 1, name: "< 25" },
          { id: 2, name: "< 50" },
          { id: 2, name: "> 50" },
        ].map((option) => option.name)}
        selectedItems={selectedWoonplaats}
        handleCheckboxChange={handleWoonplaatsCheckboxChange}
        getHouseCount={getHouseCountForFilter}
        label="w"
      />

      <Filter
        title="Zoekers"
        options={[
          { id: 1, name: "Ja" },
          { id: 2, name: "Nee" },
        ].map((option) => option.name)}
        selectedItems={selectedCBSBuurt}
        handleCheckboxChange={handleCBSBuurtCheckboxChange}
        getHouseCount={getHouseCountForFilter}
        label="b"
      />

      <Filter
        title="Leeg"
        options={[
          { id: 1, name: "Ja" },
          { id: 2, name: "Nee" },
        ].map((option) => option.name)}
        selectedItems={selectedCBSBuurt}
        handleCheckboxChange={handleCBSBuurtCheckboxChange}
        getHouseCount={getHouseCountForFilter}
        label="b"
      />
    </div>
  );
}

export default MarketFilters;
