import { Tab, TabList, Tooltip, makeStyles } from '@fluentui/react-components';

const useStyles = makeStyles({
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: '25px',
  },
  heading: {
    marginLeft: '5px',
    marginBottom: '5px',
  },
  iconTab: {
    top: 0,
    position: 'sticky',
    zIndex: 1,
    paddingBottom: '10px',
    boxShadow: '1px 1px 3px 0px rgba(0, 0, 0, 0.132), 2px 0px 6px 0 rgba(0, 0, 0, 0.108)',
    backgroundColor: '#ffffff',
    paddingLeft: '20px',
    paddingRight: '15px',
  },
});

const createImageElement = (
  src: string,
  height: string | number | undefined,
  padding: number,
  border: string,
  background: string,
  borderRadius: number,
) => {
  return (
    <img
      src={`${process.env.PUBLIC_URL}/${src}`}
      alt="icon tabs"
      style={{
        height: `${height}px`,
        padding: `${padding}px`,
        border,
        background,
        borderRadius: `${borderRadius}px`,
        transition: 'box-shadow 0.3s ease-in-out',
      }}
    />
  );
};

const IconSwitchTabs = ({ handleVastgoedClick, handleKlantClick, handleContractClick, handleMarketClick }) => {
  const styles = useStyles();
  const tabs = [
    {
      content: 'Vastgoed',
      value: 'tab1',
      clickHandler: handleVastgoedClick,
      image: 'Vastgoed Rood.svg',
      imageStyle: {
        size: 32,
        borderWidth: 4,
        borderColor: '2px solid #BC1D1D ',
        background: '#ffffff 0% 0% no-repeat padding-box',
        borderRadius: 10,
      },
    },
    {
      content: 'Klant',
      value: 'tab2',
      clickHandler: handleKlantClick,
      image: 'Klant Groen.svg',
      imageStyle: {
        size: 32,
        borderWidth: 3,
        borderColor: '2px solid #91c03e',
        background: '#ffffff 0% 0% no-repeat padding-box',
        borderRadius: 10,
      },
    },
    {
      content: 'Contract',
      value: 'tab3',
      clickHandler: handleContractClick,
      image: 'Contract Blauw.svg',
      imageStyle: {
        size: 32,
        borderWidth: 3,
        borderColor: '2px solid #00A8DC',
        background: '#ffffff 0% 0% no-repeat padding-box',
        borderRadius: 10,
      },
    },
    {
      content: 'Markt',
      value: 'tab4',
      clickHandler: handleMarketClick,
      image: 'market.svg',
      imageStyle: {
        size: 32,
        borderWidth: 3,
        borderColor: '2px solid #000985',
        background: '#ffffff 0% 0% no-repeat padding-box',
        borderRadius: 10,
      },
    },
  ];

  return (
    <div className={styles.iconTab}>
      <div className={styles.buttonContainer}>
        <TabList defaultSelectedValue="tab1">
          {tabs.map((tab) => (
            <Tooltip key={tab.value} content={tab.content} relationship="label">
              <Tab value={tab.value} onClick={tab.clickHandler}>
                {createImageElement(
                  tab.image,
                  tab.imageStyle.size,
                  tab.imageStyle.borderWidth,
                  tab.imageStyle.borderColor,
                  tab.imageStyle.background,
                  tab.imageStyle.borderRadius,
                )}
              </Tab>
            </Tooltip>
          ))}
        </TabList>
      </div>
    </div>
  );
};

export default IconSwitchTabs;
