import filterConfig from "../config/FilterConfig";
import L from "leaflet";

interface Building {
  id: string;
  name: string;
  housesCount: number;
  vges: any[];
}

export const initializeState = () => {
  const state = {
    searchQuery: "",
    showExpiredHouses: false,
    monthsRange: 0,
    sliderLabel: "Expiratiedatum binnen 0 maanden",
    clusterGroup: undefined as L.MarkerClusterGroup | undefined,
    selectedVges: null as any[] | null,
    selectedVge: null as any,
    filteredHouses: [] as any[],
    filteredHouseCount: 0,
    filteredHousesInView: [] as any[],
    housesInView: [] as any[],
    buildings: [] as Building[],
    showKlantContent: false,
    showContractContent: false,
    showMarketContent: false,
    activeId: undefined as number | undefined,
  };

  filterConfig.forEach(({ stateKey, filteredKey }) => {
    state[stateKey] = [] as string[];
    if (filteredKey) {
      state[filteredKey] = [] as string[];
    }
  });
  return state;
};
