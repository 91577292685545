import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import "leaflet/dist/leaflet.css";
import L, { LatLngTuple } from "leaflet";
import "leaflet.markercluster";
import { MapContainer } from "react-leaflet";
import "./styles/styles.css";
import "leaflet-fullscreen/dist/Leaflet.fullscreen";
import GoogleStreetView from "./components/GoogleMaps/GoogleStreetView";
import MapControls from "./components/MapComponents/MapControlButtons";
import IconSwitchTabs from "./components/IconTabs/IconSwitchTabs";
import { House } from "./interfaces/House";
import filterConfig from "config/FilterConfig";
import RightSideBarResults from "./components/ResultsSideBar/RightSideBarResults";
import LocationDetailsContainer from "./components/LocationDetails/LocationDetailsContainer";
import SelectedFilters from "./components/SelectedFilters/SelectedFilters";
import { useStyles } from "./styles/index.styles";
import { initializeState } from "./utils/initializeState";
import MapEvents from "./components/MapComponents/MapEvents";
import StreetViewInitializer from "./components/MapComponents/StreetViewInitializer";
import { Labels } from "./constants/EPOnline";
import MapInitializer from "./components/MapComponents/MapInitializer";
import ContentComponent from "./components/Filters/DynamicFilters";
import { useTenant } from "context/TenantContext";
import SearchBoxComponent from "components/MapComponents/SearchBoxComponent";

const RadarMap: React.FC = () => {
  const styles = useStyles();
  const { tenant } = useTenant();
  const [state, setState] = useState(initializeState());
  const [dataType, setDataType] = useState<string>("vasgoed");
  const initialPosition: LatLngTuple = useMemo(() => [52.295, 7.0317], []);
  const initialZoomLevel = 8;
  const mapMaxZoomLevel = 18;
  const mapRef = useRef<L.Map | null>(null);

  const handleSearchChange = useCallback((_, data) => {
    setState((prevState) => ({
      ...prevState,
      searchQuery: data || "",
    }));
    filterHouses(data || "");
  }, []);

  useEffect(() => {
    focusMap(state.filteredHouses);
    setState((prevState) => ({
      ...prevState,
      selectedVge: null,
      selectedVges: null,
    }));
  }, [state.filteredHouses]);

  const handleResultClicked = (vge) => {
    setState((prevState) => ({
      ...prevState,
      selectedVge: vge,
    }));
  };

  const handleCloseParentContainer = () => {
    setState((prevState) => ({
      ...prevState,
      selectedVges: null,
    }));
  };

  const searchVges = (searchQuery: string, filteredHouses: any[]) => {
    const searchValue = searchQuery.trim().toLowerCase();

    const checkIncludes = (house, fields) =>
      fields.some((field) =>
        (house[field] || "").toString().toLowerCase().includes(searchValue)
      );

    const searchedVges = filteredHouses.filter((house) => {
      if (!searchValue) return true;

      const filterKeys = filterConfig.map((config) => config.filterKey);

      return checkIncludes(house, filterKeys);
    });

    setState((prevState) => ({
      ...prevState,
      filteredHouses: searchedVges,
      filteredHouseCount: searchedVges.length,
    }));
  };

  const loadJsonData = async (
    tenant: string,
    type: string
  ): Promise<House[]> => {
    const fileMap = {
      klant: () => import(`./tenants/${tenant}/klant.json`),
      contract: () => import(`./tenants/${tenant}/contract.json`),
      market: () => import(`./tenants/${tenant}/market.json`),
      vastgoed: () => import(`./tenants/${tenant}/vasgoed.json`),
    };
    const loadFile = fileMap[type] || fileMap.vastgoed;
    const data = await loadFile();
    return data.default as House[];
  };

  const groupHousesByBuilding = useCallback((xs: any[]) => {
    return xs.reduce((rv, x) => {
      (rv[x.g!] = rv[x.g!] || []).push(x);
      return rv;
    }, {});
  }, []);

  const focusMap = useCallback(
    (buildings: any[]) => {
      const flyOptions = {
        duration: 1,
        easeLinearity: 0.65,
        maxZoom: mapMaxZoomLevel,
      };

      if (mapRef.current && buildings.length > 0) {
        const bounds = L.latLngBounds(buildings.map((b) => [b.lat, b.lng]));
        mapRef.current.flyToBounds(bounds, flyOptions);
      } else {
        mapRef.current?.flyTo(initialPosition, initialZoomLevel, flyOptions);
      }
    },
    [mapMaxZoomLevel, initialPosition, initialZoomLevel]
  );

  const clearAllFilters = useCallback(() => {
    const clearedState = filterConfig.reduce((acc, { stateKey }) => {
      acc[stateKey] = [];
      return acc;
    }, {});

    setState((prevState) => ({
      ...prevState,
      ...clearedState,
      monthsRange: 0,
      showExpiredHouses: false,
      sliderLabel: "Expiratiedatum binnen 0 maanden",
      filteredHousesInView: [],
      searchQuery: "",
    }));
  }, [setState]);

  const handleClick = useCallback(
    (type: string) => {
      setDataType(type);
      clearAllFilters();
      loadJsonData(tenant, type).then((newHouses) => {
        const filteredBuildings = groupHousesByBuilding(newHouses);
        const buildings = Object.keys(filteredBuildings).map((key) => ({
          id: key,
          name: "",
          housesCount: filteredBuildings[key].length,
          vges: filteredBuildings[key],
        }));

        setState((prevState) => ({
          ...prevState,
          showKlantContent: type === "klant",
          showContractContent: type === "contract",
          showMarketContent: type === "market",
          filteredHouses: newHouses,
          filteredHouseCount: newHouses.length,
          buildings: buildings,
        }));
        focusMap(newHouses);
      });
    },
    [tenant, clearAllFilters, groupHousesByBuilding, focusMap]
  );

  useEffect(() => {
    handleClick(dataType);
  }, [tenant, handleClick, dataType]);
  

  const handleKlantClick = () => handleClick("klant");
  const handleContractClick = () => handleClick("contract");
  const handleMarketClick = () => handleClick("market");
  const handleVastgoedClick = () => handleClick("vasgoed");

  useEffect(() => {
    filterHouses(state.searchQuery);
  }, [
    dataType,
    ...filterConfig.map((config) => state[config.stateKey]),
    state.showExpiredHouses,
    state.monthsRange,
  ]);

  const filterHouses = async (searchQuery) => {
    const baseHouses = await loadJsonData(tenant, dataType);

    const filterConditions = filterConfig.map(({ filterKey, stateKey }) => ({
      key: filterKey,
      values: state[stateKey] || [],
    }));

    const dateWithinRange = (houseDate, rangeStart, rangeEnd) => {
      const date = houseDate ? new Date(houseDate) : null;
      return date && date > rangeStart && date < rangeEnd;
    };

    const today = new Date();
    const futureDate = new Date(today);
    futureDate.setMonth(today.getMonth() + state.monthsRange);

    const isHouseExpired = (house) =>
      dateWithinRange(house.e_g, new Date(0), today);
    const isHouseExpiringWithinMonths = (house) =>
      dateWithinRange(house.e_g, today, futureDate);

    const matchesFilter = (house) =>
      filterConditions.every(
        ({ key, values }) =>
          values.length === 0 || values.includes(house[key] ?? "")
      );

    const filteredHouses = baseHouses.filter((house) => {
      if (!matchesFilter(house)) return false;
      if (state.showExpiredHouses && isHouseExpired(house)) return true;
      if (state.monthsRange > 0 && isHouseExpiringWithinMonths(house))
        return true;
      return true;
    });

    const filteredBuildings = groupHousesByBuilding(filteredHouses);
    const buildings = Object.keys(filteredBuildings).map((key) => ({
      id: key,
      name: "",
      housesCount: filteredBuildings[key].length,
      vges: filteredBuildings[key],
    }));

    const prioritizeFilterOptions = (options, relevantOptions) =>
      relevantOptions
        .sort(
          (a, b) =>
            filteredHouses.filter((house) => house.srt === b).length -
            filteredHouses.filter((house) => house.srt === a).length
        )
        .concat(options.filter((option) => !relevantOptions.includes(option)));

    const relevantOptions = (key) =>
      Array.from(
        new Set(filteredHouses.map((house) => house[key] ?? ""))
      ).sort();

    const newState = {
      buildings,
      filteredHouseCount: filteredHouses.length,
      filteredHouses,
    };

    filterConfig.forEach(({ filteredKey, filterKey }) => {
      if (filteredKey) {
        newState[filteredKey] = prioritizeFilterOptions(
          state[filteredKey],
          relevantOptions(filterKey)
        );
      }
    });

    setState((prevState) => ({
      ...prevState,
      ...newState,
    }));

    if (searchQuery.trim()) {
      searchVges(searchQuery, filteredHouses);
    }
  };

  const handleCloseLocationDetails = () => {
    setState((prevState) => ({
      ...prevState,
      selectedVge: null,
    }));
  };

  const handleViewportChange = (bounds) => {
    const housesInView = state.filteredHouses.filter((house) => {
      const { lat, lng } = house;
      return (
        lat >= bounds._southWest.lat &&
        lat <= bounds._northEast.lat &&
        lng >= bounds._southWest.lng &&
        lng <= bounds._northEast.lng
      );
    });
    setState((prevState) => ({
      ...prevState,
      housesInView: housesInView,
    }));
  };

  useEffect(() => {
    if (mapRef.current) {
      MapInitializer({
        mapRef,
        initialPosition,
        initialZoomLevel,
        mapMaxZoomLevel,
        state,
        focusMap,
        onPlotSelect: (plotInfo) => {
          setState((prevState) => ({
            ...prevState,
            selectedPlot: plotInfo,
          }));
        },
        onPlotDeselect: () => {
          setState((prevState) => ({
            ...prevState,
            selectedPlot: null,
          }));
        },
      })(mapRef.current);
    }
  }, [mapRef.current]);
  
  return (
    <div className={styles.container}>
      <div className={styles.parentContainer}>
        <IconSwitchTabs
          handleVastgoedClick={handleVastgoedClick}
          handleKlantClick={handleKlantClick}
          handleContractClick={handleContractClick}
          handleMarketClick={handleMarketClick}
        />
        <div className={styles.parentContainerContent}>
          <SelectedFilters state={state} setState={setState} />
          <ContentComponent state={state} setState={setState} Labels={Labels} />
        </div>
        <footer className={styles.footer}>
          {state.filteredHouseCount} Vastgoedeenheden
        </footer>
      </div>
      <SearchBoxComponent
        searchQuery={state.searchQuery}
        handleSearchChange={handleSearchChange}
        styles={styles}
      />
      <div className={styles.sideRight}>
        {state.selectedVge && (
          <div className={styles.itemDetail}>
            <LocationDetailsContainer
              selectedVge={state.selectedVge}
              handleCloseLocationDetails={handleCloseLocationDetails}
              initializeStreetView={StreetViewInitializer}
              isKlantContent={state.showKlantContent}
            />
          </div>
        )}
        <GoogleStreetView
          selectedVge={state.selectedVge}
          handleCloseLocationDetails={handleCloseLocationDetails}
        />
        <MapContainer
          className={styles.mapContainer}
          center={initialPosition}
          zoom={initialZoomLevel}
          maxZoom={mapMaxZoomLevel}
          zoomControl={false}
          ref={mapRef}
        >
          <MapEvents
            mapRef={mapRef}
            state={state}
            setState={setState}
            initialZoomLevel={initialZoomLevel}
            mapMaxZoomLevel={mapMaxZoomLevel}
            handleViewportChange={handleViewportChange}
          />
          <MapControls
            focusMap={() => focusMap(state.filteredHouses)}
            setFilteredHousesInView={(housesInView) =>
              setState((prevState) => ({
                ...prevState,
                filteredHousesInView: housesInView,
              }))
            }
            housesInView={state.housesInView}
          />
        </MapContainer>
      </div>
      {state.selectedVges !== null && (
        <RightSideBarResults
          selectedVges={state.selectedVges}
          handleCloseParentContainer={handleCloseParentContainer}
          handleResultClicked={handleResultClicked}
          activeId={state.activeId}
          setActiveId={(id) =>
            setState((prevState) => ({ ...prevState, activeId: id }))
          }
          isKlantContent={state.showKlantContent}
        />
      )}
    </div>
  );
};

export default RadarMap;
