import React from "react";
import { Checkbox, makeStyles } from "@fluentui/react-components";
import FilterCheckboxProps from "interfaces/FilterCheckbox";

const useStyles = makeStyles({
  filterLabel: {
    display: "flex",
    alignItems: "center",
    position: "relative"
  }
});

const FilterCheckbox: React.FC<FilterCheckboxProps> = ({
  label,
  checked,
  onChange,
  houseCount,
}) => {
  const styles = useStyles();
  return (
    <div className={styles.filterLabel}>
      <Checkbox label={label} checked={checked} onChange={onChange} />
      <span style={{ marginLeft:"auto"}}>{houseCount}</span>
    </div>
  );
};

export default FilterCheckbox;
