import { makeStyles, tokens } from "@fluentui/react-components";
import {
  ContactCardRegular,
  WrenchRegular,
  PeopleToolboxRegular,
  PeopleTeamToolboxRegular,
} from "@fluentui/react-icons";

const useStyles = makeStyles({
  iconColor: {
    color: tokens.colorBrandForeground1,
    fontSize: "24px",
  },
  longText: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: "280px",
  },
  listResults: {
    padding: '5px 15px',
    borderBottom: '1px solid #f5f5f5',
    cursor: 'pointer',
    transition: 'box-shadow 0.1s ease-in-out',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
    '&[aria-current="true"]': {
      backgroundColor: '#ebbf4413',
    },
  },
  symbolContainer: {
    display: "grid",
    gridRowGap: "8px",
  },
  symbols: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    columnGap: "3px",
  },
  houseInfo: {
    margin: "0px",
    fontSize: "14px",
    fontWeight: "bold",
  },
  houseType: {
    margin: "0px",
  },
  houseDetail: {
    margin: "0px",
  },
});

const SymbolWithIcon: React.FC<{ Icon: React.ElementType; text: string; }> = ({ Icon, text }) => {
  const styles = useStyles();
  return (
    <div className={styles.symbols}>
      <Icon className={styles.iconColor} />
      <p className={styles.houseDetail}>{text}</p>
    </div>
  );
};

const KlantItem = ({ house, clickHandle, activeId, setActiveId }) => {
  const styles = useStyles();

  return (
    <li className={styles.listResults} key={house.id} aria-current={house.id === activeId ? 'true' : undefined}>
      <div
        onClick={() => {
          clickHandle(house);
          setActiveId(house.id);
        }}
      >
        <div className={styles.houseInfo}>
          <p className={styles.houseType}>
            <span>{house.Type}</span>
          </p>
        </div>
        <div className={styles.symbolContainer}>
          <SymbolWithIcon Icon={ContactCardRegular} text={house.Oorsprong} />
          <SymbolWithIcon Icon={WrenchRegular} text={house.SoortReparatieverzoek} />
          <SymbolWithIcon Icon={PeopleToolboxRegular} text={house.Uitvoerder} />
          <SymbolWithIcon Icon={PeopleTeamToolboxRegular} text={house.TeamUitvoerder} />
        </div>
      </div>
    </li>
  );
};

export default KlantItem;
