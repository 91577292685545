import { BrandVariants, createLightTheme } from "@fluentui/react-components";

const CustomBrand: BrandVariants = {
  10: "#2B2000",
  20: "#523B00",
  30: "#774E00",
  40: "#9C6200",
  50: "#BF7500",
  60: "#DD9700",
  70: "#E2A31A",
  80: "#E7AE33",
  90: "#EBB94C",
  100: "#F0C565",
  110: "#F4D27E",
  120: "#F9DEA5",
  130: "#FDEACD",
  140: "#FEF3E1",
  150: "#FFF8F0",
  160: "#FFFCFA"
};

const CustomTheme = createLightTheme(CustomBrand);

export default CustomTheme;
