import { GooglePhoto } from "../GoogleMaps/GooglePhoto";

const KlantLocationDetails = ({ vge, initializeStreetView }) => {
  return (
    <div style={{ cursor: "pointer" }} onClick={initializeStreetView}>
      <GooglePhoto houseData={vge} />
    </div>
  );
};

export default KlantLocationDetails;
