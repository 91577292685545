import { makeStyles, tokens, shorthands, CompoundButton, mergeClasses, Button, Field, SearchBox } from "@fluentui/react-components";
import { DrawerBody, DrawerHeader, DrawerHeaderTitle, Drawer, DrawerFooter } from "@fluentui/react-components/unstable";
import { useTenant } from "context/TenantContext";
import { ChangeEvent, useEffect, useState } from "react";

type TenantPickerProps = {
  shouldOpen?: boolean;
  onClose?: () => void;
};

const useStyles = makeStyles({
  drawer: {
    width: "350px",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    rowGap: tokens.spacingVerticalM,
  },
  current: {
    display: "flex",
    flexDirection: "column",
    rowGap: tokens.spacingVerticalS,
  },
  list: {
    display: "flex",
    flexDirection: "column",
    rowGap: tokens.spacingVerticalM,
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    columnGap: tokens.spacingHorizontalM,
  },
  tenantButton: {
    justifyContent: "flex-start",
    alignItems: "center",
    ...shorthands.padding(
      tokens.spacingHorizontalSNudge,
      tokens.spacingVerticalM,
      tokens.spacingHorizontalS,
      tokens.spacingVerticalM
    ),
  },
  tenantButtonSelected: {
    color: tokens.colorBrandBackground2, 
    backgroundColor: tokens.colorBrandForeground1,
    "&:hover": {
      backgroundColor: tokens.colorBrandBackground, 
      color: tokens.colorBrandBackground2,
    },
  },
  logo: {
    width: '24px',
    height: '24px',
    marginRight: tokens.spacingHorizontalS,
  },
  tenantContent: {
    display: 'flex',
    alignItems: 'center',
  },
  fieldWrapper: {
    padding: `${tokens.spacingVerticalL} ${tokens.spacingHorizontalNone}`,
  }
});

function TenantPicker(props: TenantPickerProps) {
  const { shouldOpen, onClose } = props;
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const styles = useStyles();

  const { tenant, setTenant } = useTenant();
  const [selectedTenant, setSelectedTenant] = useState(tenant);

  const handleTenantChange = (tenantName: string) => {
    setSelectedTenant(tenantName);
  };

  const tenants = [
    { name: "Baston Wonen", logo: "/logos/baston-wonen.jpg", disabled: true },
    { name: "Bo-Ex", logo: "/logos/bo-ex.jpg", disabled: true },
    { name: "Eigenhaard", logo: "/logos/EigenHaard.jpg", disabled: false },
    { name: "ElanWonen", logo: "/logos/elanwonen.jpg", disabled: true },
    { name: "Havensteder", logo: "/logos/havensteder.jpg", disabled: false },
    { name: "Lieven de Key", logo: "/logos/lieven-de-key.jpg", disabled: true }, 
    { name: "Mozaiek Wonen", logo: "/logos/mozaiek-wonen.jpg", disabled: true },
    { name: "Nijestee", logo: "/logos/nijestee.jpg", disabled: true },
    { name: "OosterpoortWoon", logo: "/logos/oosterpoortwoon.jpg", disabled: true }, 
    { name: "Rijnhart Wonen", logo: "/logos/rijnhart-wonen.jpg", disabled: true },
    { name: "Servatius", logo: "/logos/servatius.jpg", disabled: false },
    { name: "Vivare", logo: "/logos/vivare.jpg", disabled: false },
    { name: "Waterweg Wonen", logo: "/logos/waterweg-wonen.jpg", disabled: true },
    { name: "Woonbron", logo: "/logos/woonbron.jpg", disabled: true },
    { name: "Woongroen", logo: "/logos/woongroen.jpg", disabled: true },
    { name: "Woonplaats", logo: "/logos/deWoonplaats.jpg", disabled: false },
    { name: "Woonzorg Nederland", logo: "/logos/woonzorg-nederland.jpg", disabled: false },
  ];
  
  useEffect(() => {
    if (shouldOpen) {
      setOpen(true);
    }
  }, [shouldOpen]);

  useEffect(() => {
    if (!open && onClose) {
      onClose();
    }
  }, [open, onClose]);

  const handleSearchChange = (
    ev: ChangeEvent<HTMLInputElement> | undefined,
    data: string | undefined
  ) => {
    setSearchQuery(data || "");
  };

  return (
    <Drawer
      type="overlay"
      position="end"
      separator
      open={open}
      onOpenChange={(event, data) => setOpen(data.open)}
      className={styles.drawer}
    >
      <DrawerHeader>
        <DrawerHeaderTitle>Wisselen van tenant</DrawerHeaderTitle>
        <Field className={styles.fieldWrapper}>
          <SearchBox
            placeholder="Zoeken"
            appearance="underline"
            value={searchQuery}
            onChange={(_, data) => handleSearchChange(undefined, data.value)}
          />
        </Field>
      </DrawerHeader>

      <DrawerBody>
        <div className={styles.container}>
          <div className={styles.list}>
            {tenants
              .filter((tenantValue) =>
                tenantValue.name
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase())
              )
              .map((tenantValue) => (
                <CompoundButton
                  key={tenantValue.name}
                  className={mergeClasses(
                    styles.tenantButton,
                    selectedTenant === tenantValue.name &&
                      styles.tenantButtonSelected
                  )}
                  onClick={() => handleTenantChange(tenantValue.name)}
                  disabled={tenantValue.disabled}
                >
                  <div className={styles.tenantContent}>
                    <img
                      src={tenantValue.logo}
                      alt={`${tenantValue.name} logo`}
                      className={styles.logo}
                      loading="lazy"
                    />
                    {tenantValue.name}
                  </div>
                </CompoundButton>
              ))}
          </div>
        </div>
      </DrawerBody>
      <DrawerFooter className={styles.footer}>
        <Button onClick={() => setOpen(false)} appearance="subtle">
          Annuleren
        </Button>
        <Button
          appearance="primary"
          onClick={() => {
            if (selectedTenant) {
              setTenant(selectedTenant);
              setOpen(false);
            }
          }}
          disabled={!selectedTenant}
        >
          Wisselen
        </Button>
      </DrawerFooter>
    </Drawer>
  );
}

export default TenantPicker;
