import React from "react";
import VgeItem from "./VgeItem";
import KlantItem from "./KlantItem";
import { Dismiss24Regular } from "@fluentui/react-icons";
import { Button, makeStyles, tokens } from "@fluentui/react-components";

const useStyles = makeStyles({
  footer: {
    marginTop: "10px",
    position: "sticky",
    bottom: 0,
    fontSize: "16px",
    backgroundColor: tokens.colorBrandForeground1,
    color: tokens.colorBrandBackground2,
    padding: "15px 0",
    textAlign: "center",
    border: "solid 15px white",
    boxShadow: "0px -1px 2px 0px rgba(0, 0, 0, 0.132), 0px -1px 7px 0px rgba(0, 0, 0, 0.108)",
  },
  parentContainer: {
    display: "flex",
    flexDirection: "column",
    height: "95vh",
    width: "320px",
    boxShadow: "0 0 10px 2px rgb(0 0 0 / 20%)",
    zIndex: 1000,
  },

  zoekresultaat: {
    display: "flex",
    alignItems: "baseline",
    borderBottom: "1px solid #f5f5f5",
    paddingLeft: "20px",
    paddingRight: "15px",
  },
  listView: {
    overflowY: 'auto',
    overflowX: 'hidden',
    flexGrow: 1,
  },
  listItems: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },
});

interface ParentContainerProps {
  selectedVges: any[];
  handleCloseParentContainer: () => void;
  handleResultClicked: (vge: any) => void;
  activeId: number | undefined;
  setActiveId: (id: number) => void;
  isKlantContent: boolean;
}

const RightSideBarResults: React.FC<ParentContainerProps> = ({
  selectedVges,
  handleCloseParentContainer,
  handleResultClicked,
  activeId,
  setActiveId,
  isKlantContent,
}) => {
  const styles = useStyles();

  return (
    <div className={styles.parentContainer}>
        <div className={styles.zoekresultaat}>
          <h3>Zoekresultaat</h3>
          <div style={{ marginLeft: "auto", cursor: "pointer" }}>
            <Button
              appearance="subtle"
              icon={<Dismiss24Regular />}
              onClick={handleCloseParentContainer}
            />
          </div>
        </div>
      <div className={styles.listView}>
        <ul className={styles.listItems}>
          {selectedVges?.map((house) =>
            isKlantContent ? (
              <KlantItem
                key={house.id}
                house={house}
                clickHandle={handleResultClicked}
                activeId={activeId}
                setActiveId={setActiveId}
              />
            ) : (
              <VgeItem
                key={house.id}
                house={house}
                clickHandle={handleResultClicked}
                activeId={activeId}
                setActiveId={setActiveId}
              />
            )
          )}
        </ul>
      </div>
      <footer className={styles.footer}>{selectedVges.length} Vastgoedeenheden</footer>
    </div>
  );
};

export default RightSideBarResults;
