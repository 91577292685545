import React from "react";
import { House } from "../../interfaces/House";

const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;

const googlePhotoSourceBuilder = (
  address: (string | undefined)[],
  lat?: number,
  long?: number
) => {
  if (lat && long) {
    return `https://maps.googleapis.com/maps/api/streetview?size=397x270&location=${lat},${long}&fov=90&pitch=10&key=${apiKey}`;
  }

  const filteredAddress: string = address
    .filter((x) => x !== undefined)
    .join(", ");
  return `https://maps.googleapis.com/maps/api/streetview?size=397x270&location=${filteredAddress}&fov=90&pitch=10&key=${apiKey}`;
};

interface GooglePhotoProps {
  houseData: House;
}

export const GooglePhoto: React.FC<GooglePhotoProps> = ({ houseData }) => {
  const lat = houseData.lat || 0;
  const long = houseData.lng || 0;
  const wpl = houseData.w || "";
  const dSrt = houseData.s || "";
  const nr = houseData.nr?.toString() || "";
  const nrT = houseData.dSrt || "";
  const ltr = houseData.b || "";

  return (
    <img
      style={{ height: "240px", width: "420px" }}
      src={googlePhotoSourceBuilder([wpl, dSrt, nr, nrT, ltr], lat, long)}
      alt="Geen foto van de locatie gevonden"
    />
  );
};
