export const Labels = [
    { name: 'A+++', color: "#009037", gradientLength: "66%" } as Label,
    { name: 'A++', color: "#009037", gradientLength: "60%" } as Label,
    { name: 'A+', color: "#009037", gradientLength: "54%" } as Label,
    { name: 'A', color: "#009037", gradientLength: "48%" } as Label,
    { name: 'B', color: "#55ab26", gradientLength: "45%" } as Label,
    { name: 'C', color: "#B9D100", gradientLength: "42%" } as Label,
    { name: 'D', color: "#D0D500", gradientLength: "39%" } as Label,
    { name: 'E', color: "#faba00", gradientLength: "36%" } as Label,
    { name: 'F', color: "#eb6909", gradientLength: "33%" } as Label,
    { name: 'G', color: "#e2001a", gradientLength: "30%" } as Label,
]

export interface Label {
    name: string;
    color: string;
    gradientLength: string;
}