import { useMap } from "react-leaflet";
import { Button, Tooltip, makeStyles } from "@fluentui/react-components";
import { ArrowHookDownLeftRegular, ZoomFitRegular, ZoomInRegular } from '@fluentui/react-icons';

const useStyles = makeStyles({
  mapControls: {
    display: "flex",
    justifyContent: "space-between",
    columnGap: "8px",
    position: "absolute",
    left: "20px",
    top: "62px",
    zIndex: 1000,
  },
  mapControlButton: {
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    width: "35px",
    height: "33px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    transition: "box-shadow 0.3s ease-in-out",

    "&:hover": {
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", 
    },
  },
});

const MapControlButton = ({ tooltipContent, onClick, icon }) => {
  const styles = useStyles();
  return (
    <Tooltip content={tooltipContent} relationship="label" positioning="below">
      <Button className={styles.mapControlButton} onClick={onClick} icon={icon} />
    </Tooltip>
  );
};

const HomeButton = () => {
  const map = useMap();
  const handleHomeButtonClick = () => {
    map.flyTo([52.295, 7.0317], 8, {
      duration: 0.5,
      easeLinearity: 0.5,
    });
  };
  return (
    <MapControlButton
      tooltipContent="Zet kaartweergave terug naar oorspronkelijke staat"
      onClick={handleHomeButtonClick}
      icon={<ArrowHookDownLeftRegular />}
    />
  );
};

const FocusOnFilteredHousesButton = ({ focusMap }) => (
  <MapControlButton
    tooltipContent="Breng gefilterd vastgoed naar voren"
    onClick={focusMap}
    icon={<ZoomFitRegular />}
  />
);

const FilteredHousesToInViewButton = ({ setFilteredHousesInView, housesInView }) => {
  const map = useMap();
  const handleFilteredHousesToInView = () => {
    const bounds = housesInView.map(house => [house.lat, house.lng]);
    if (bounds.length > 0) {
      map.fitBounds(bounds, {
        padding: [50, 50],
        duration: 0.5,
        easeLinearity: 0.5,
      });
    }
    setFilteredHousesInView(housesInView);
  };
  return (
    <MapControlButton
      tooltipContent="Filter naar vastgoed in weergave"
      onClick={handleFilteredHousesToInView}
      icon={<ZoomInRegular />}
    />
  );
};

const MapControls = ({ focusMap, setFilteredHousesInView, housesInView }) => {
  const styles = useStyles();
  return (
    <div className={styles.mapControls}>
      <HomeButton />
      <FocusOnFilteredHousesButton focusMap={focusMap} />
      <FilteredHousesToInViewButton
        setFilteredHousesInView={setFilteredHousesInView}
        housesInView={housesInView}
      />
    </div>
  );
};

export default MapControls;
