import ReactDOM from "react-dom/client";
import App from "App";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "config/msalConfig";
import { TenantProvider } from "context/TenantContext";
const msalInstance = new PublicClientApplication(msalConfig);

const startup = async () => {

  const root = ReactDOM.createRoot(document.getElementById("root"));

  root.render(
    <MsalProvider instance={msalInstance}>
      <TenantProvider>
        <App />
      </TenantProvider>
    </MsalProvider>
  );
};

startup();
