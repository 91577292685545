import { PublicClientApplication, InteractionRequiredAuthError } from "@azure/msal-browser";
import axios from "axios";
import IGraphUserProfile from "interfaces/graphUserProfile";
import { graphConfig } from "../config/graphConfig";
import { msalConfig } from "../config/msalConfig";

const msalInstance = new PublicClientApplication(msalConfig);

export const signIn = async (inProgress: string) => {
  if (inProgress === "none") {
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length === 0) {
      try {
        await msalInstance.loginRedirect({ scopes: graphConfig.scopes });
      } catch (error) {
        console.error("Error during login:", error);
      }
    }
  }
};

export const getAccessToken = async (inProgress: string) => {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length === 0) return null;

  const request = {
    scopes: graphConfig.scopes,
    account: accounts[0],
  };

  try {
    const response = await msalInstance.acquireTokenSilent(request);
    return response.accessToken;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError && inProgress === "none") {
      await msalInstance.acquireTokenRedirect(request);
    } else {
      console.error("Error acquiring token:", error);
      return null;
    }
  }
};

export const fetchUserDetails = async (accessToken: string): Promise<IGraphUserProfile> => {
  const response = await axios.get(graphConfig.graphMeEndpoint, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return response.data;
};

export const fetchUserPhoto = async (accessToken: string): Promise<string> => {
  const response = await axios.get(graphConfig.graphMyPhotoEndpoint, {
    responseType: "arraybuffer",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const imageUrl = URL.createObjectURL(
    new Blob([response.data], {
      type: response.headers["content-type"],
    })
  );
  return imageUrl;
};

export const fetchUserData = async (accessToken: string | null) => {
  if (!accessToken) {
    console.error("Failed to acquire access token");
    return null;
  }
  const user = await fetchUserDetails(accessToken);
  const photo = await fetchUserPhoto(accessToken);
  return { user, photo };
};
