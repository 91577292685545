import { InteractionType } from "@azure/msal-browser";
import { useMsal, useMsalAuthentication } from "@azure/msal-react";
import { useEffect } from "react";
import RadarMap from "./RadarMap";
import { FluentProvider } from "@fluentui/react-components";
import Toolbar from "components/Toolbar/Navbar";
import CustomTheme from "themes/PrimaryTheme";

export const App = () => {
  useMsalAuthentication(InteractionType.Redirect);

  const { accounts, instance } = useMsal();
  const activeAccount = accounts[0];

  useEffect(() => {
    if (accounts.length > 0) {
      instance.setActiveAccount(activeAccount);
    }
  }, [accounts.length, activeAccount, instance]);

  return (
    <>
      <FluentProvider theme={CustomTheme}>
        <Toolbar />
        <RadarMap />
      </FluentProvider>
    </>
  );
};

export default App;
