import { useState } from "react";
import { ChevronDown24Regular, ChevronUp24Regular } from '@fluentui/react-icons';
import Filter from "./CollapsibleFilter";
import { Label, makeStyles, Slider, Switch } from "@fluentui/react-components";

const useStyles = makeStyles({
  sideLeft: {
    backgroundColor: "#ffffff",
    paddingLeft: "20px",
    paddingRight: "15px"
  },
  dropdownContainer: {
    position: "relative",
    maxHeight: "none",
    overflow: "visible"
  },
  checkboxContainer: {
    maxHeight: "none",
    overflow: "visible"
  },
  dropdownToggle: {
    cursor: "pointer",
    color: "#000000",
    height: "40px",
    border: "none",
    borderRadius: "none",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "centre",
    width:"100%",
    marginLeft: "auto",
        
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  },
  toggleOption: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  }
});

function FilterComponent(props) {
  const styles = useStyles();
  const [energielabelDropdownVisible, setEnergielabelDropdownVisible] =
    useState(false);
  const showEnergielabelOptionsOnClick = () => {
    setEnergielabelDropdownVisible(!energielabelDropdownVisible);
  };
  const {
    filteredEenheidDetailssoort,
    filteredEenheidsoort,
    filteredWijk,
    selectedWijk,
    handleWijkCheckboxChange,
    filteredGemeenschappelijk,
    selectedGemeenschappelijk,
    handleGemeenschappelijkCheckboxChange,
    filteredBouwjaar,
    selectedBouwjaar,
    handleBouwjaarCheckboxChange,
    selectedeenheidsoort,
    selectedEenheidDetailsoort,
    handleEenheidsoortCheckboxChange,
    handleEenheidDetailsoortCheckboxChange,
    getHouseCountForFilter,
    filteredWoonplaatsen,
    selectedWoonplaats,
    handleWoonplaatsCheckboxChange,
    filteredCBSBuurtNamen,
    selectedCBSBuurt,
    handleCBSBuurtCheckboxChange,
    monthsRange,
    setMonthsRange,
    showExpiredHouses,
    setShowExpiredHouses,
    epOnlineLabels,
    Labels,
    sliderLabel = "Initial Label",
    setSliderLabel,
    filteredStraat,
    selectedStraat,
    handleStraatCheckboxChange,
  } = props;

  return (
    <div className={styles.sideLeft}>
      <h2 className="heading">Indeling</h2>
      <Filter
        title="Woonplaats"
        options={filteredWoonplaatsen}
        selectedItems={selectedWoonplaats}
        handleCheckboxChange={handleWoonplaatsCheckboxChange}
        getHouseCount={getHouseCountForFilter}
        label="w"
      />

      <Filter
        title="Wijk"
        options={filteredWijk}
        selectedItems={selectedWijk}
        handleCheckboxChange={handleWijkCheckboxChange}
        getHouseCount={getHouseCountForFilter}
        label="wk"
      />

      <Filter
        title="Buurt"
        options={filteredCBSBuurtNamen}
        selectedItems={selectedCBSBuurt}
        handleCheckboxChange={handleCBSBuurtCheckboxChange}
        getHouseCount={getHouseCountForFilter}
        label="b"
      />

      <Filter
        title="Straat"
        options={filteredStraat}
        selectedItems={selectedStraat}
        handleCheckboxChange={handleStraatCheckboxChange}
        getHouseCount={getHouseCountForFilter}
        label="s"
      />

      <h2 className="heading">Type</h2>

      <Filter
        title="Eenheidsoort"
        options={filteredEenheidsoort}
        selectedItems={selectedeenheidsoort}
        handleCheckboxChange={handleEenheidsoortCheckboxChange}
        getHouseCount={getHouseCountForFilter}
        label="srt"
      />

      <Filter
        title="Eenheiddetailsoort"
        options={filteredEenheidDetailssoort}
        selectedItems={selectedEenheidDetailsoort}
        handleCheckboxChange={handleEenheidDetailsoortCheckboxChange}
        getHouseCount={getHouseCountForFilter}
        label="dSrt"
      />

      <Filter
        title="Gemeenschappelijke ruimten"
        options={filteredGemeenschappelijk}
        selectedItems={selectedGemeenschappelijk}
        handleCheckboxChange={handleGemeenschappelijkCheckboxChange}
        getHouseCount={getHouseCountForFilter}
        label="gemeenschappelijk"
      />

      <Filter
        title="Verhuurbaar"
        selectedItems={selectedEenheidDetailsoort}
        handleCheckboxChange={handleEenheidDetailsoortCheckboxChange}
        getHouseCount={getHouseCountForFilter}
        label="srt"
        options={[
          { id: 1, name: "Zelfstandig" },
          { id: 2, name: "Onzelfstandig" },
          { id: 3, name: "Gegroepeerd" },
          { id: 4, name: "Niet Verhuurbaar" },
        ].map((option) => option.name)}
      />

      <Filter
        title="Gebruiksdoelen"
        selectedItems={selectedEenheidDetailsoort}
        handleCheckboxChange={handleEenheidDetailsoortCheckboxChange}
        getHouseCount={getHouseCountForFilter}
        label="srt"
        options={[
          { id: 1, name: "Wonen" },
          { id: 2, name: "Bijeenkomsten" },
          { id: 3, name: "Gezondheidszorg" },
          { id: 4, name: "Onderwijs" },
          { id: 5, name: "Sport" },
          { id: 6, name: "Winkel" },
          { id: 7, name: "Kantoor" },
          { id: 8, name: "Industrie" },
          { id: 9, name: "Logies" },
          { id: 10, name: "Detentie" },
        ].map((option) => option.name)}
      />

      <h2 className="heading">Woning</h2>

      <div className={styles.dropdownContainer}>
        <div
          className={styles.dropdownToggle}
          onClick={showEnergielabelOptionsOnClick}
        >
          <h3>Energielabel</h3>
          <div style={{marginTop: "13px"}}>
          {energielabelDropdownVisible ? <ChevronUp24Regular /> : <ChevronDown24Regular />}
          </div>
        </div>
        {energielabelDropdownVisible ? (

            <div>
              <div className={styles.checkboxContainer}>
                <div>
                  <div>
                    <div className={styles.toggleOption}>
                      <Label>Expiratiedatum verlopen</Label>
                      <Switch
                        style={{ marginTop: "10px" }}
                        labelPosition="before"
                        checked={showExpiredHouses}
                        onChange={() =>
                          setShowExpiredHouses(!showExpiredHouses)
                        }
                      />
                    </div>
                  </div>
                  <div>
                    <Label>{sliderLabel}</Label>
                    <Slider
                      min={0}
                      max={12}
                      step={1}
                      defaultValue={0}
                      value={monthsRange}
                      onChange={(e, data) => {
                        setMonthsRange(data.value);
                        setSliderLabel(
                          `Expiratiedatum binnen ${data.value} maanden`
                        );
                      }}
                      disabled={showExpiredHouses}
                      style={{
                        width: "250px",
                      }}
                    />
                  </div>
                </div>
                {epOnlineLabels(Labels)}
              </div>
          </div>
        ) : null}
      </div>

      <Filter
        title="Gebruiksoppervlakte"
        selectedItems={selectedEenheidDetailsoort}
        handleCheckboxChange={handleEenheidDetailsoortCheckboxChange}
        getHouseCount={getHouseCountForFilter}
        label="srt"
        options={[
          { id: 1, name: "<25m²" },
          { id: 2, name: "25-50m²" },
          { id: 3, name: "50-75m²" },
          { id: 4, name: "75-100m²" },
          { id: 5, name: "100-150m²" },
          { id: 6, name: ">150m²" },
        ].map((option) => option.name)}
      />

      <Filter
        title="Slaapkamers"
        selectedItems={selectedEenheidDetailsoort}
        handleCheckboxChange={handleEenheidDetailsoortCheckboxChange}
        getHouseCount={getHouseCountForFilter}
        label="srt"
        options={[
          { id: 1, name: "1" },
          { id: 2, name: "2" },
          { id: 3, name: "3" },
          { id: 4, name: "4" },
          { id: 5, name: ">4" },
        ].map((option) => option.name)}
      />

      <Filter
        title="Balkon"
        selectedItems={selectedEenheidDetailsoort}
        handleCheckboxChange={handleEenheidDetailsoortCheckboxChange}
        getHouseCount={getHouseCountForFilter}
        label="srt"
        options={[
          { id: 1, name: "Ja" },
          { id: 2, name: "Nee" },
        ].map((option) => option.name)}
      />

      <Filter
        title="Etage"
        selectedItems={selectedEenheidDetailsoort}
        handleCheckboxChange={handleEenheidDetailsoortCheckboxChange}
        getHouseCount={getHouseCountForFilter}
        label="srt"
        options={[
          { id: 1, name: "Kelder" },
          { id: 2, name: "Begane grond" },
          { id: 3, name: "1ste verdieping" },
          { id: 4, name: "2de verdieping" },
          { id: 5, name: "3de verdieping" },
          { id: 6, name: "4de verdieping" },
          { id: 7, name: "5de verdieping" },
          { id: 8, name: "6de verdieping" },
          { id: 9, name: "7de verdieping" },
          { id: 10, name: "8de verdieping" },
          { id: 11, name: "9de verdieping" },
          { id: 12, name: "10de verdieping" },
          { id: 13, name: "11de verdieping" },
          { id: 14, name: "12de verdieping" },
          { id: 15, name: "13de verdieping" },
          { id: 16, name: "14de verdieping" },
          { id: 17, name: "15de verdieping" },
        ].map((option) => option.name)}
      />

      <Filter
        title="Verwarming"
        selectedItems={selectedEenheidDetailsoort}
        handleCheckboxChange={handleEenheidDetailsoortCheckboxChange}
        getHouseCount={getHouseCountForFilter}
        label="srt"
        options={[
          { id: 1, name: "Collectief" },
          { id: 2, name: "Lucht verwarming" },
          { id: 3, name: "Vloer verwarming" },
          { id: 4, name: "Radiatoren" },
          { id: 5, name: "Warmtepomp" },
        ].map((option) => option.name)}
      />

      <Filter
        title="Toegankelijkheid"
        selectedItems={selectedEenheidDetailsoort}
        handleCheckboxChange={handleEenheidDetailsoortCheckboxChange}
        getHouseCount={getHouseCountForFilter}
        label="srt"
        options={[
          { id: 1, name: "Gelijkvloers" },
          { id: 2, name: "Rolstoel geschikt" },
          { id: 3, name: "Traplift" },
        ].map((option) => option.name)}
      />

      <h2 className="heading">Waarde</h2>

      <Filter
        title="WOZ-waarde"
        selectedItems={selectedEenheidDetailsoort}
        handleCheckboxChange={handleEenheidDetailsoortCheckboxChange}
        getHouseCount={getHouseCountForFilter}
        label="srt"
        options={[
          { id: 1, name: "< 50.000" },
          { id: 2, name: "50.000 - 100.000" },
          { id: 3, name: "100.000 - 150.000" },
          { id: 4, name: "200.000 - 250.000" },
          { id: 5, name: "250.000 - 300.000" },
          { id: 6, name: "> 300.000" },
        ].map((option) => option.name)}
      />

      <Filter
        title="Beleidswaarde"
        selectedItems={selectedEenheidDetailsoort}
        handleCheckboxChange={handleEenheidDetailsoortCheckboxChange}
        getHouseCount={getHouseCountForFilter}
        label="srt"
        options={[{ id: 1, name: "Zelfde als WOZ" }].map(
          (option) => option.name
        )}
      />

      <Filter
        title="Marktwaarde"
        selectedItems={selectedEenheidDetailsoort}
        handleCheckboxChange={handleEenheidDetailsoortCheckboxChange}
        getHouseCount={getHouseCountForFilter}
        label="srt"
        options={[{ id: 1, name: "Zelfde als WOZ" }].map(
          (option) => option.name
        )}
      />
      <Filter
        title="Fiscale waarde"
        selectedItems={selectedEenheidDetailsoort}
        handleCheckboxChange={handleEenheidDetailsoortCheckboxChange}
        getHouseCount={getHouseCountForFilter}
        label="srt"
        options={[{ id: 1, name: "Zelfde als WOZ" }].map(
          (option) => option.name
        )}
      />
      <Filter
        title="Historische kostprijs"
        selectedItems={selectedEenheidDetailsoort}
        handleCheckboxChange={handleEenheidDetailsoortCheckboxChange}
        getHouseCount={getHouseCountForFilter}
        label="srt"
        options={[{ id: 1, name: "Zelfde als WOZ" }].map(
          (option) => option.name
        )}
      />

      <Filter
        title="Rendement"
        selectedItems={selectedEenheidDetailsoort}
        handleCheckboxChange={handleEenheidDetailsoortCheckboxChange}
        getHouseCount={getHouseCountForFilter}
        label="srt"
        options={[
          { id: 1, name: "< 2%" },
          { id: 2, name: "< 5%" },
          { id: 3, name: "< 10%" },
          { id: 4, name: "> 10%" },
        ].map((option) => option.name)}
      />

      <h2 className="heading">Perceel</h2>

      <Filter
        title="Grond"
        selectedItems={selectedEenheidDetailsoort}
        handleCheckboxChange={handleEenheidDetailsoortCheckboxChange}
        getHouseCount={getHouseCountForFilter}
        label="srt"
        options={[
          { id: 1, name: "< 20" },
          { id: 2, name: "21-40" },
          { id: 3, name: "41-60" },
          { id: 4, name: "61-80" },
          { id: 5, name: "> 80" },
        ].map((option) => option.name)}
      />

      <Filter
        title="Bebouwd"
        selectedItems={selectedEenheidDetailsoort}
        handleCheckboxChange={handleEenheidDetailsoortCheckboxChange}
        getHouseCount={getHouseCountForFilter}
        label="srt"
        options={[
          { id: 1, name: "Ja" },
          { id: 2, name: "Nee" },
        ].map((option) => option.name)}
      />

      <Filter
        title="Bouwjaar"
        options={filteredBouwjaar}
        selectedItems={selectedBouwjaar}
        handleCheckboxChange={handleBouwjaarCheckboxChange}
        getHouseCount={getHouseCountForFilter}
        label="bouwjaar"
      />

      <Filter
        title="Renovatiejaar"
        selectedItems={selectedEenheidDetailsoort}
        handleCheckboxChange={handleEenheidDetailsoortCheckboxChange}
        getHouseCount={getHouseCountForFilter}
        label="srt"
        options={[
          { id: 1, name: "< 1990" },
          { id: 2, name: "1990 - 2000" },
          { id: 3, name: "2000 - 2004" },
          { id: 4, name: "2010 - 2014" },
          { id: 5, name: "2015 -2019" },
          { id: 6, name: ">2020" },
        ].map((option) => option.name)}
      />

      <h2 className="heading">Juridisch</h2>

      <Filter
        title="VvE"
        selectedItems={selectedEenheidDetailsoort}
        handleCheckboxChange={handleEenheidDetailsoortCheckboxChange}
        getHouseCount={getHouseCountForFilter}
        label="srt"
        options={[
          { id: 1, name: "Ja" },
          { id: 2, name: "Nee" },
        ].map((option) => option.name)}
      />

      <Filter
        title="Zakelijke rechten"
        selectedItems={selectedEenheidDetailsoort}
        handleCheckboxChange={handleEenheidDetailsoortCheckboxChange}
        getHouseCount={getHouseCountForFilter}
        label="sr"
        options={[
          { id: 1, name: "Eigendom" },
          { id: 2, name: "Erfdienstbaarheid" },
          { id: 3, name: "Erfpacht" },
          { id: 4, name: "Opstalrecht" },
          { id: 5, name: "Appartememtsrecht" },
          { id: 6, name: "Overig" },
        ].map((option) => option.name)}
      />

      <Filter
        title="Verkoop onder voorwaarden"
        selectedItems={selectedEenheidDetailsoort}
        handleCheckboxChange={handleEenheidDetailsoortCheckboxChange}
        getHouseCount={getHouseCountForFilter}
        label="srt"
        options={[
          { id: 1, name: "Koopgarant" },
          { id: 2, name: "Anders" },
        ].map((option) => option.name)}
      />

      <Filter
        title="Monument"
        selectedItems={selectedEenheidDetailsoort}
        handleCheckboxChange={handleEenheidDetailsoortCheckboxChange}
        getHouseCount={getHouseCountForFilter}
        label="srt"
        options={[
          { id: 1, name: "Rijk " },
          { id: 2, name: "Provincie " },
          { id: 3, name: "Gemeente " },
        ].map((option) => option.name)}
      />

      <h2 className="heading">Beheer</h2>

      <Filter
        title="Plan"
        selectedItems={selectedEenheidDetailsoort}
        handleCheckboxChange={handleEenheidDetailsoortCheckboxChange}
        getHouseCount={getHouseCountForFilter}
        label="srt"
        options={[
          { id: 1, name: "Exploiteren" },
          { id: 2, name: "Transformeren " },
          { id: 3, name: "Slopen" },
          { id: 4, name: "Verkopen" },
        ].map((option) => option.name)}
      />

      <Filter
        title="Projecten"
        selectedItems={selectedEenheidDetailsoort}
        handleCheckboxChange={handleEenheidDetailsoortCheckboxChange}
        getHouseCount={getHouseCountForFilter}
        label="srt"
        options={[
          { id: 1, name: "Slopen" },
          { id: 2, name: "Bouwen" },
          { id: 3, name: "Groot Onderhoud" },
          { id: 4, name: "Renoveren" },
          { id: 5, name: "Restaureren" },
        ].map((option) => option.name)}
      />
    </div>
  );
}

export default FilterComponent;
