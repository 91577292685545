import { createMarkers } from "utils/markerUtils";
import { useMapEvents } from "react-leaflet";

const MapEvents = ({
  mapRef,
  state,
  setState,
  initialZoomLevel,
  mapMaxZoomLevel,
  handleViewportChange,
}) => {
  useMapEvents({
    moveend: (event) => {
      const bounds = event.target.getBounds();
      handleViewportChange(bounds);
    },
    zoomend: async (event) => {
      await createMarkers(
        mapRef,
        state,
        setState,
        event.sourceTarget.getZoom(),
        initialZoomLevel,
        mapMaxZoomLevel
      );
    },
  });
  return null;
};

export default MapEvents;
