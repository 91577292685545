import L, { point, FeatureGroup } from "leaflet";
import CustomTheme from "themes/PrimaryTheme";

const CustomClusterIcon = (cluster) => {
  return L.divIcon({
    html: `<span class="cluster-icon">${cluster.getChildCount()}</span>`,
    className: "custom-marker-cluster",
    iconSize: point(22, 22, true),
  });
};

const CustomIcon = (vgeID) => {
  return L.divIcon({
    html: `<span class="cluster-icon" id="${vgeID}">1</span>`,
    className: "custom-marker-cluster",
    iconSize: point(22, 22, true),
  });
};

const parseXML = (xmlString: string) => {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xmlString, "application/xml");
  return xmlDoc;
};

const fetchPlotData = async (bbox: [number, number, number, number]) => {
  const [minLng, minLat, maxLng, maxLat] = bbox;
  const wmsUrl = `https://service.pdok.nl/kadaster/kadastralekaart/wms/v5_0?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetFeatureInfo&LAYERS=kadastralekaart&QUERY_LAYERS=kadastralekaart&INFO_FORMAT=application/json&FEATURE_COUNT=50&SRS=EPSG:4326&BBOX=${minLng},${minLat},${maxLng},${maxLat}&WIDTH=800&HEIGHT=600&X=400&Y=300`;

  console.log(`Fetching plot data for BBOX: ${bbox}`);
  console.log(`WMS Request URL: ${wmsUrl}`);

  try {
    const response = await fetch(wmsUrl);
    const responseText = await response.text();

    console.log('Response status:', response.status);
    console.log('Response text:', responseText);

    if (!response.ok) {
      throw new Error(`Error fetching plot data: ${response.statusText}`);
    }

    if (!responseText) {
      console.error(`Empty response for BBOX: ${bbox}`);
      return null;
    }

    const jsonStartIndex = responseText.indexOf('{');
    const jsonEndIndex = responseText.lastIndexOf('}');
    if (jsonStartIndex !== -1 && jsonEndIndex !== -1) {
      const jsonString = responseText.substring(jsonStartIndex, jsonEndIndex + 1);
      try {
        const data = JSON.parse(jsonString);
        console.log('Parsed JSON data:', data);

        if (!data.features || data.features.length === 0) {
          console.warn(`No plot found for BBOX: ${bbox}`);
          return null;
        }

        return L.geoJSON(data.features, {
          style: {
            color: '#dd9700',
            weight: 2,
            opacity: 1,
            fillOpacity: 0.5,
          }
        });
      } catch (jsonError) {
        console.error('Error parsing JSON:', jsonError);
        console.error('Extracted JSON string:', jsonString);
        return null;
      }
    } else {
      console.error('JSON content not found in the response text:', responseText);
      const xmlDoc = parseXML(responseText);
      const serviceException = xmlDoc.querySelector("ServiceException");
      if (serviceException) {
        console.error(`WMS Server Error: ${serviceException.textContent}`);
      }
      return null;
    }
  } catch (error) {
    console.error(`Error fetching plot data for BBOX: ${bbox}:`, error);
    return null;
  }
};

export const createMarkers = async (
  mapRef,
  state,
  setState,
  currentZoom,
  initialZoomLevel,
  mapMaxZoomLevel
) => {
  if (mapRef.current) {
    state.clusterGroup?.removeFrom(mapRef.current);
  }

  const zoomRatio = initialZoomLevel / currentZoom;
  const clusterRadius = 125 * zoomRatio;

  let highlightedPlotsLayer: FeatureGroup<any> = new L.FeatureGroup();

  const handleClusterClick = async (e) => {
    const clusterIcon = e.layer._icon.querySelector(".cluster-icon");
    if (clusterIcon.classList.contains("selected")) {
      clusterIcon.classList.remove("selected");
      setState((prevState) => ({
        ...prevState,
        selectedVge: null,
        selectedVges: null,
      }));
      if (highlightedPlotsLayer) {
        mapRef.current.removeLayer(highlightedPlotsLayer);
        highlightedPlotsLayer = new L.FeatureGroup();
      }
      return;
    }

    setState((prevState) => ({
      ...prevState,
      selectedVge: null,
      selectedVges: null,
    }));

    document.querySelectorAll(".cluster-icon").forEach((icon) => {
      icon.classList.remove("selected");
    });

    const bottomCluster = e.propagatedFrom;

    if (bottomCluster._childClusters.length >= 1) return false;

    clusterIcon.classList.add("selected");

    const markerIDs = bottomCluster.getAllChildMarkers().map((m) => {
      const elementID = m.options.icon.options.html.match(/id="(\d+)"/)?.[1];
      return Number.parseInt(elementID);
    });

    const vges = state.filteredHouses.filter((f) => markerIDs.includes(f.id));

    if (highlightedPlotsLayer) {
      mapRef.current.removeLayer(highlightedPlotsLayer);
    }

    const plotDataPromises = vges.map((vge) => {
      if (!vge.bbox) {
        console.error(`VGE object with ID ${vge.id} has no bounding box.`);
        return Promise.resolve(null);
      }
      return fetchPlotData(vge.bbox);
    });

    const plotDataLayers = await Promise.all(plotDataPromises);
    const validLayers = plotDataLayers.filter(Boolean) as L.Layer[];

    highlightedPlotsLayer = L.featureGroup(validLayers).addTo(mapRef.current);

    if (highlightedPlotsLayer.getBounds().isValid()) {
      mapRef.current.fitBounds(highlightedPlotsLayer.getBounds());
    }

    setState((prevState) => ({
      ...prevState,
      selectedVges: vges,
    }));
  };

  const clusters = L.markerClusterGroup({
    iconCreateFunction: CustomClusterIcon,
    polygonOptions: {
      fillColor: CustomTheme.colorBrandForeground2,
      color: CustomTheme.colorBrandForeground2,
    },
    disableClusteringAtZoom: mapMaxZoomLevel + 1,
    spiderfyOnMaxZoom: false,
    maxClusterRadius: clusterRadius,
  }).on("clusterclick", handleClusterClick);

  const handleMarkerClick = (vgeID, element) => {
    const isSelected = element.classList.contains("selected");

    if (isSelected) {
      element.classList.remove("selected");
      setState((prevState) => ({
        ...prevState,
        selectedVge: null,
      }));
    } else {
      document.querySelectorAll(".cluster-icon").forEach((icon) => {
        icon.classList.remove("selected");
      });

      element.classList.add("selected");

      const selectedVge = state.filteredHouses.find((fh) => fh.id === vgeID);
      setState((prevState) => ({
        ...prevState,
        selectedVge: selectedVge,
        selectedVges: null,
      }));
    }
  };

  state.filteredHouses.forEach((vge) => {
    const marker = L.marker(L.latLng(vge.lat, vge.lng), {
      icon: CustomIcon(vge.id),
    });

    marker.on("click", (e) => {
      const element = e.originalEvent.target as HTMLElement;
      handleMarkerClick(vge.id, element);
    });

    clusters.addLayer(marker);
  });

  mapRef.current?.addLayer(clusters);
  setState((prevState) => ({
    ...prevState,
    clusterGroup: clusters,
  }));

  return true;
};
