import FilterConfig from "interfaces/FilterConfigType";

const filterConfig: FilterConfig[] = [
  {
    filterKey: "w",
    stateKey: "selectedWoonplaats",
    handler: "handleWoonplaatsCheckboxChange",
    filteredKey: "filteredWoonplaatsen",
    filterCategory: "dynamic",
  },
  {
    filterKey: "b",
    stateKey: "selectedCBSBuurt",
    handler: "handleCBSBuurtCheckboxChange",
    filteredKey: "filteredCBSBuurtNamen",
    filterCategory: "dynamic",
  },
  {
    filterKey: "srt",
    stateKey: "selectedeenheidsoort",
    handler: "handleEenheidsoortCheckboxChange",
    filteredKey: "filteredEenheidsoort",
    filterCategory: "dynamic",
  },
  {
    filterKey: "dSrt",
    stateKey: "selectedEenheidDetailsoort",
    handler: "handleEenheidDetailsoortCheckboxChange",
    filteredKey: "filteredEenheidDetailssoort",
    filterCategory: "dynamic",
  },
  {
    filterKey: "wk",
    stateKey: "selectedWijk",
    handler: "handleWijkCheckboxChange",
    filteredKey: "filteredWijk",
    filterCategory: "dynamic",
  },
  {
    filterKey: "gemeenschappelijk",
    stateKey: "selectedGemeenschappelijk",
    handler: "handleGemeenschappelijkCheckboxChange",
    filteredKey: "filteredGemeenschappelijk",
    filterCategory: "dynamic",
  },
  {
    filterKey: "e_l",
    stateKey: "selectedLabelletters",
    filteredKey: "filteredLabelletter",
    filterCategory: "dynamic",
  },
  {
    filterKey: "s",
    stateKey: "selectedStraat",
    handler: "handleStraatCheckboxChange",
    filteredKey: "filteredStraat",
    filterCategory: "dynamic",
  },
  {
    filterKey: "bouwjaar",
    stateKey: "selectedBouwjaar",
    handler: "handleBouwjaarCheckboxChange",
    filteredKey: "filteredBouwjaar",
    filterCategory: "dynamic",
  },
  // Klant filters
  {
    filterCategory: "klant",
    title: "Status",
    filterKey: "status",
    stateKey: "selectedStatuses",
    handler: "handleStatusCheckboxChange",
    filteredKey: "filteredStatusess",
  },
  {
    filterCategory: "klant",
    title: "Type",
    filterKey: "Type",
    stateKey: "selectedTypes",
    handler: "handleTypeCheckboxChange",
    filteredKey: "filteredTypes",
  },
  {
    filterCategory: "klant",
    title: "Soort Reparatieverzoek",
    filterKey: "SoortReparatieverzoek",
    stateKey: "selectedSoortReparatieverzoek",
    handler: "handleSoortReparatieverzoekCheckboxChange",
    filteredKey: "filteredSoortReparatieverzoeke",
  },
  {
    filterCategory: "klant",
    title: "Oorsprong",
    filterKey: "Oorsprong",
    stateKey: "selectedOorsprongs",
    handler: "handleOorsprongsCheckboxChange",
    filteredKey: "filteredOorsprongs",
  },
  {
    filterCategory: "klant",
    title: "Team Uitvoerder",
    filterKey: "TeamUitvoerder",
    stateKey: "selectedTeamUitvoerders",
    handler: "handleTeamUitvoerderCheckboxChange",
    filteredKey: "filteredTeamUitvoerders",
  },
  {
    filterCategory: "klant",
    title: "Uitvoerder",
    filterKey: "Uitvoerder",
    stateKey: "selectedUitvoerders",
    handler: "handleUitvoerderCheckboxChange",
    filteredKey: "filteredUitvoerders",
  },
  // Account filters
  {
    filterCategory: "account",
    title: "Account Status",
    filterKey: "accountStatus",
    stateKey: "selectedAccountStatuses",
    handler: "handleAccountStatusCheckboxChange",
    filteredKey: "filteredAccountStatuses",
  },
  {
    filterCategory: "account",
    title: "Account Type",
    filterKey: "accountType",
    stateKey: "selectedAccountTypes",
    handler: "handleAccountTypeCheckboxChange",
    filteredKey: "filteredAccountTypes",
  },
  {
    filterCategory: "account",
    title: "Account Origin",
    filterKey: "accountOrigin",
    stateKey: "selectedAccountOrigins",
    handler: "handleAccountOriginCheckboxChange",
    filteredKey: "filteredAccountOrigins",
  },
];

export default filterConfig;
