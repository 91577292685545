import L, { GeoJSON } from "leaflet";
import "leaflet-fullscreen/dist/Leaflet.fullscreen";
import { bgLayer, overlayLayer } from "../../nlmaps-leaflet/nlmaps-leaflet/src/index";

const MapInitializer = ({
  mapRef,
  initialPosition,
  initialZoomLevel,
  mapMaxZoomLevel,
  state,
  focusMap,
  onPlotSelect,
  onPlotDeselect,
}) => {
  let selectedPlotLayer: GeoJSON<any> | null = null;

  const initMap = (mapInstance: L.Map) => {
    mapRef.current = mapInstance;

    const nlmapLayer = bgLayer("standaard");
    const nlmapPercelenlayer = overlayLayer("percelen");

    const createGoogleTileLayer = (type: string) => {
      return L.tileLayer(
        `https://{s}.google.com/vt/lyrs=${type}&x={x}&y={y}&z={z}`,
        {
          attribution: "Google Maps",
          maxZoom: mapMaxZoomLevel,
          subdomains: ["mt0", "mt1", "mt2", "mt3"],
        }
      );
    };

    const googleMapsSat = createGoogleTileLayer("s");
    const googleMaps = createGoogleTileLayer("m");
    googleMaps.addTo(mapRef.current);

    mapRef.current.addControl(
      new L.Control.Fullscreen({
        title: "Toggle Fullscreen",
        position: "bottomright",
        forceSeparateButton: true,
        pseudoFullscreen: false,
        fullscreenElement: false,
      })
    );

    const layerControl = L.control
      .layers(undefined, undefined, { position: "topleft" })
      .addTo(mapRef.current);

    const addLayer = (layerControl, layer, imagePath, altText, isBaseLayer = true) => {
      const layerHTML = `<div class='control-layer-option'><img src='${imagePath}' alt='${altText}'/></div>`;

      if (isBaseLayer) {
        layerControl.addBaseLayer(layer, layerHTML);
      } else {
        layerControl.addOverlay(layer, layerHTML);
      }
    };

    addLayer(layerControl, googleMaps, "/GoogleMaps.png", "Google Maps");
    addLayer(layerControl, googleMapsSat, "/GoogleMapsSat.png", "Google Maps Satellite");
    addLayer(layerControl, nlmapLayer, "/NLMaps.png", "NL Maps");
    addLayer(layerControl, nlmapPercelenlayer, "/PercelenOverlay.png", "NL Maps Percelen", false);

    focusMap(state.filteredHouses);

    const handlePlotSelect = async (latlng) => {
      if (selectedPlotLayer) {
        mapRef.current.removeLayer(selectedPlotLayer);
        selectedPlotLayer = null;
        onPlotDeselect();
        return;
      }

      const mapSize = mapRef.current.getSize();
      const bounds = mapRef.current.getBounds();
      const bbox = `${bounds.getWest()},${bounds.getSouth()},${bounds.getEast()},${bounds.getNorth()}`;

      const url = `https://service.pdok.nl/kadaster/kadastralekaart/wms/v5_0?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetFeatureInfo&LAYERS=kadastralekaart&QUERY_LAYERS=kadastralekaart&INFO_FORMAT=application/json&FEATURE_COUNT=1&SRS=EPSG:4326&X=${Math.floor(latlng.x)}&Y=${Math.floor(latlng.y)}&WIDTH=${mapSize.x}&HEIGHT=${mapSize.y}&BBOX=${bbox}`;

      try {
        const response = await fetch(url);
        const text = await response.text();

        const jsonStartIndex = text.indexOf('{');
        const jsonEndIndex = text.lastIndexOf('}');
        if (jsonStartIndex !== -1 && jsonEndIndex !== -1) {
          const jsonString = text.substring(jsonStartIndex, jsonEndIndex + 1);
          try {
            const data = JSON.parse(jsonString);
            if (data.features && data.features.length > 0) {
              const plotInfo = data.features[0];
              onPlotSelect(plotInfo); 

              selectedPlotLayer = L.geoJSON(plotInfo, {
                style: {
                  color: '#dd9700',
                  weight: 2,
                  opacity: 1,
                  fillOpacity: 0.5,
                },
              }).addTo(mapRef.current);
              if (selectedPlotLayer) {
                mapRef.current.fitBounds(selectedPlotLayer.getBounds());
              }
            }
          } catch (jsonError) {
            console.error('Error parsing JSON:', jsonError);
            console.error('Extracted JSON string:', jsonString);
          }
        } else {
          console.error('JSON content not found in the response text:', text);
        }
      } catch (error) {
        console.error('Error fetching plot data:', error);
      }
    };

    mapRef.current.on('click', function (e) {
      handlePlotSelect(mapRef.current.latLngToContainerPoint(e.latlng));
    });
  };

  return initMap;
};

export default MapInitializer;
