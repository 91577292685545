import { Labels } from "../../constants/EPOnline";
import { RealEstateRegular, SlideSizeRegular, PositionForwardFilled } from '@fluentui/react-icons';
import { LightningBoltIcon } from '@fluentui/react-icons-mdl2';
import { makeStyles, tokens } from "@fluentui/react-components";

interface VasgoedItemProps {
  house: any;
  clickHandle: (vge: any) => void;
  activeId: number | undefined;
  setActiveId: (id: number) => void;
}

const useStyles = makeStyles({
  iconColor: {
    color: tokens.colorBrandForeground1,
    fontSize: "24px",
  },
  longText: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: "280px",
  },
  listResults: {
    padding: '5px 15px',
    borderBottom: '1px solid #f5f5f5',
    cursor: 'pointer',
    transition: 'box-shadow 0.1s ease-in-out',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
    '&[aria-current="true"]': {
      backgroundColor: '#ebbf4413',
    },
  },
  gridContainer: {
    display: "grid",
    gridRowGap: "8px",
  },
  symbols: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    columnGap: "3px",
  },
  columnContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  houseInfo: {
    display: "flex",
    alignItems: "center",
    margin: "0px",
    fontSize: "14px",
    fontWeight: "bold",
  },
  houseDetail: {
    fontSize: "10px",
    color: "#838383",
    marginRight: "3px",
    margin: "0px",
  },
  lightningIcon: {
    marginTop: "2px",
  },
  houseLabel: {
    marginLeft: "1px",
    margin: "0px",
    fontSize: "14px",
  },
});

const SymbolWithIcon: React.FC<{ Icon: React.ElementType; text: string; title: string; style?: React.CSSProperties }> = ({ Icon, text, title, style }) => {
  const styles = useStyles();
  return (
    <div className={styles.columnContainer} style={style}>
      <div className={styles.symbols}>
        <Icon className={styles.iconColor} />
        <p className={styles.longText} title={title}>
          {text}
        </p>
      </div>
    </div>
  );
};

const VasgoedItem: React.FC<VasgoedItemProps> = ({
  house,
  clickHandle,
  activeId,
  setActiveId,
}) => {
  const styles = useStyles();
  const label = Labels.find((l) => l.name === house.e_l);

  return (
    <li className={styles.listResults} key={house.id} aria-current={house.id === activeId ? 'true' : undefined}>
      <div
        className={styles.gridContainer}
        onClick={() => {
          clickHandle(house);
          setActiveId(house.id);
        }}
      >
        <p className={styles.houseInfo}>
          {house.s} {house.nr}
          <p className={styles.houseDetail}> - {house.w}</p>
        </p>
        <div className={styles.gridContainer}>
          <div className={styles.symbols}>
            <SymbolWithIcon Icon={RealEstateRegular} text={house.dSrt} title={house.dSrt} />
            <SymbolWithIcon Icon={SlideSizeRegular} text={`${house.oppervlakte}m²`} title={house.oppervlakte} style={{ marginLeft: "15px" }} />
            <SymbolWithIcon Icon={PositionForwardFilled} text="4" title={house.oppervlakte} style={{ marginLeft: "15px" }} />
          </div>
          <div className={styles.symbols}>
            <div className={styles.lightningIcon}>
              <LightningBoltIcon style={{ color: label?.color }} />
            </div>
            <p className={styles.houseLabel}>{house.e_l}</p>
          </div>
        </div>
      </div>
    </li>
  );
};

export default VasgoedItem;
