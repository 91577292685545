import debounce from "lodash/debounce";
import { useCallback, useEffect, useState, useMemo } from "react";
import { SearchBox } from "@fluentui/react-components";

const SearchBoxComponent = ({ searchQuery, handleSearchChange, styles }) => {
  const [internalQuery, setInternalQuery] = useState(searchQuery);

  useEffect(() => {
    setInternalQuery(searchQuery);
  }, [searchQuery]);

  const debouncedHandleSearchChange = useMemo(
    () => debounce((data) => {
      handleSearchChange(undefined, data);
    }, 1000),
    [handleSearchChange]
  );

  const handleChange = useCallback((_, data) => {
    setInternalQuery(data.value);
    debouncedHandleSearchChange(data.value);
  }, [debouncedHandleSearchChange]);

  return (
    <SearchBox
      value={internalQuery}
      onChange={handleChange}
      placeholder="Zoeken"
      className={styles.searchBox}
    />
  );
};

export default SearchBoxComponent;
