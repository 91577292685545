import { Label } from "constants/EPOnline";
import { Checkbox } from "@fluentui/react-components";
import { useStyles } from "styles/index.styles";
import filterConfig from "config/FilterConfig";

const EPOnlineFilters = ({ state, setState }) => {
  const styles = useStyles();
  
  const handleCheckboxChange = (type: string) => (item: string) => {
    setState((prevState) => ({
      ...prevState,
      [type]: prevState[type].includes(item)
        ? prevState[type].filter((prevItem) => prevItem !== item)
        : [...prevState[type], item],
    }));
  };

  const getHouseCountForFilter = (filterOption: string, ...args: string[]) => {
    var amount = 0;

    for (const house of state.filteredHouses) {
      let value = house;
      for (const arg of args) {
        value = value[arg];
      }
      if (value?.toString() === filterOption) {
        amount++;
      }
    }
    return amount;
  };

  const getFilterConfig = (filterKey) =>
    filterConfig.find((config) => config.filterKey === filterKey);

  const eponlineLabel = (labelletter: Label) => {
    const labelConfig = getFilterConfig("e_l");
    if (!labelConfig) return null;
    let labelDiv = (
      <div
        key={labelletter.name}
        className={styles.filterLabel}
        style={{
          background: `linear-gradient(31deg, ${labelletter.color} 10%, white ${labelletter.gradientLength}, white) right center / 170px 100% no-repeat`,
          height: "25px",
          margin: "5px",
        }}
      >
        <Checkbox
          label={<span style={{ fontWeight: "bold" }}>{labelletter.name}</span>}
          checked={state[labelConfig.stateKey].includes(labelletter.name)}
          onChange={() =>
            handleCheckboxChange(labelConfig.stateKey)(labelletter.name)
          }
        />
        <span className={styles.houseCount}>
          {getHouseCountForFilter(labelletter.name, labelConfig.filterKey)}
        </span>
      </div>
    );
    return <div>{labelDiv}</div>;
  };

  const epOnlineLabels = (labels: Label[]) => {
    return (
      <div className={styles.epLabels}>
        {labels.map((label) => {
          return eponlineLabel(label);
        })}
      </div>
    );
  };

  return { eponlineLabel, epOnlineLabels, getHouseCountForFilter, handleCheckboxChange };
};

export default EPOnlineFilters;
