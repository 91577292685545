import {  useEffect } from "react";

const StreetViewInitializer = ({ selectedVge, streetViewDivRef, streetViewDivparentRef }) => {
  useEffect(() => {
    if (!selectedVge) return;
    if (!streetViewDivRef.current) return;
    if (!streetViewDivparentRef.current) return;
    streetViewDivRef.current.childNodes.forEach((c) => c.remove());
    const lat = selectedVge.lat;
    const lng = selectedVge.lng;
    new google.maps.StreetViewPanorama(streetViewDivRef.current, {
      position: { lat, lng },
      fullscreenControl: false,
      clickToGo: false,
      zoomControl: false,
      scrollwheel: false,
      disableDefaultUI: true,
    });
    streetViewDivparentRef.current.style.display = "block";
  }, [selectedVge]);

  return null;
};

export default StreetViewInitializer;
