import { useRef, useEffect } from "react";
import { DismissRegular } from '@fluentui/react-icons';
import { Button } from "@fluentui/react-components";

const GoogleStreetView = ({ selectedVge, handleCloseLocationDetails }) => {
  const streetViewDivRef = useRef<HTMLDivElement>(null);
  const streetViewDivparentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (
      streetViewDivRef.current &&
      streetViewDivparentRef.current &&
      selectedVge
    ) {
      streetViewDivRef.current.childNodes.forEach((c) => c.remove());
      const lat = selectedVge.lat;
      const lng = selectedVge.lng;
      new google.maps.StreetViewPanorama(streetViewDivRef.current, {
        position: { lat, lng },
        fullscreenControl: false,
        clickToGo: false,
        zoomControl: false,
        scrollwheel: false,
        disableDefaultUI: true,
      });
      streetViewDivparentRef.current.style.display = "block";
    }
  }, [selectedVge]);

  const handleCloseStreetView = () => {
    if (streetViewDivparentRef.current) {
      streetViewDivparentRef.current.style.display = "none";
    }
    handleCloseLocationDetails();
  };

  return (
    <div
      ref={streetViewDivparentRef}
      style={{
        position: "relative",
        width: "100%",
        height: "95vh",
        zIndex: "1000",
        display: "none",
      }}
    >
      <Button
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          zIndex: "1001",
        }}
        onClick={handleCloseStreetView}
        appearance="primary"
        icon={<DismissRegular />}
      />
      <div
        style={{ width: "100%", height: "95vh" }}
        ref={streetViewDivRef}
      ></div>
    </div>
  );
};

export default GoogleStreetView;
