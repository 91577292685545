import React, { useState } from "react";
import { ChevronDown24Regular, ChevronUp24Regular } from '@fluentui/react-icons';
import { Button, makeStyles } from "@fluentui/react-components";
import FilterCheckbox from "./FilterCheckbox";
import CollapsibleFilterSectionProps from "interfaces/CollapsibleFilter";

const useStyles = makeStyles({
  dropdownToggle: {
    cursor: "pointer",
    color: "#000000",
    height: "40px",
    border: "none",
    borderRadius: "none",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "centre",
    width:"100%",
    marginLeft: "auto",
    
    "&:hover": {
      backgroundColor: "#f5f5f5",
      borderRadius: "5px",
    },
  },
  toon: {
    display: "flex",
    flexDirection: "column",
    paddingTop: "12px"
  }
});

const itemsToShowInitially = 8;

const CollapsibleFilterSection: React.FC<CollapsibleFilterSectionProps> = ({
  title,
  options = [],
  selectedItems = [],
  handleCheckboxChange,
  getHouseCount,
  label,
}) => {
  const styles = useStyles();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [showAll, setShowAll] = useState(false);

  const toggleDropdown = () => setDropdownVisible(!dropdownVisible);
  const toggleShowAll = () => setShowAll(!showAll);

  return (
    <div>
      <div className={styles.dropdownToggle} onClick={toggleDropdown}>
        <h3>{title}</h3>
        <div style={{marginTop: "13px"}}>
        {dropdownVisible ? <ChevronUp24Regular /> : <ChevronDown24Regular />}
        </div>
      </div>
      {dropdownVisible && (
        <div>
          <div>
            {options
              .slice(0, showAll ? options.length : itemsToShowInitially)
              .map((option) => (
              <FilterCheckbox
                key={option}
                label={option}
                checked={selectedItems.includes(option)}
                onChange={() => handleCheckboxChange(option)}
                houseCount={getHouseCount(option, label)}
              />
            ))}
          </div>
          {options.length > itemsToShowInitially && (
            <div className={styles.toon}>
              <Button shape="circular" onClick={toggleShowAll}>
                {showAll
                  ? `Toon minder`
                  : `Toon meer (${options.length - itemsToShowInitially})`}
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CollapsibleFilterSection;
