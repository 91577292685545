import {
  InteractionTag,
  InteractionTagPrimary,
  InteractionTagSecondary,
  makeStyles,
} from "@fluentui/react-components";
import filterConfig from "config/FilterConfig";

const useStyles = makeStyles({
  container: {
    padding: "0px 15px 0px 20px",
  },
  selectedTagFilters: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "baseline",
    justifyContent: "space-between",
    width: "100%",
  },
  text: {
    marginBottom: "10px",
  },
  selectedCount: {
    backgroundColor: "#ebbf44",
    color: "white",
    paddingTop: "2px",
    borderRadius: "50%",
    textAlign: "center",
    width: "24px",
    height: "22px",
  },
});

const SelectedFilters: React.FC<{ state: any; setState: any }> = ({
  state,
  setState,
}) => {
  const styles = useStyles();

  const totalAppliedFilters = () => {
    const additionalFilters = [
      state.monthsRange > 0,
      state.showExpiredHouses,
      state.filteredHousesInView?.length > 0,
      state.searchQuery,
    ].filter(Boolean).length;

    const configFilters = Object.values(filterConfig).reduce(
      (total, { stateKey }) => total + (state[stateKey]?.length || 0),
      0
    );

    return configFilters + additionalFilters;
  };

  const clearAllFilters = () => {
    const clearedState = Object.values(filterConfig).reduce(
      (acc, { stateKey }) => {
        acc[stateKey] = [];
        return acc;
      },
      {}
    );

    setState((prevState) => ({
      ...prevState,
      ...clearedState,
      monthsRange: 0,
      showExpiredHouses: false,
      sliderLabel: "Expiratiedatum binnen 0 maanden",
      filteredHousesInView: [],
      searchQuery: "",
    }));
  };

  const selectedFilterButton = (key, value) => (
    <InteractionTag
      key={`${key}-${value}`}
      appearance="brand"
      onClick={() => removeSelectedFilter(key, value)}
      size="small"
    >
      <InteractionTagPrimary hasSecondaryAction>
        <span>{value}</span>
      </InteractionTagPrimary>
      <InteractionTagSecondary aria-label="remove" />
    </InteractionTag>
  );

  const removeSelectedFilter = (filterType, filterValue) => {
    const filterActions: { [key: string]: (prevState: any) => any } =
      Object.values(filterConfig).reduce(
        (acc: { [key: string]: (prevState: any) => any }, { stateKey }) => {
          acc[stateKey] = (prevState: any) => ({
            ...prevState,
            [stateKey]: prevState[stateKey].filter(
              (item) => item !== filterValue
            ),
          });
          return acc;
        },
        {
          searchQuery: (prevState: any) => ({ ...prevState, searchQuery: "" }),
          showExpiredHouses: (prevState: any) => ({
            ...prevState,
            showExpiredHouses: false,
          }),
          monthsRange: (prevState: any) => ({
            ...prevState,
            monthsRange: 0,
            sliderLabel: "Expiratiedatum binnen 0 maanden",
          }),
          filteredHousesInView: (prevState: any) => ({
            ...prevState,
            filteredHousesInView: [],
          }),
        }
      );

    setState(
      (prevState) => filterActions[filterType]?.(prevState) ?? prevState
    );
  };

  const getSelectedFilters = () => {
    const selectedFilters = Object.values(filterConfig).flatMap(
      ({ stateKey }) =>
        state[stateKey]?.map((value) => selectedFilterButton(stateKey, value))
    );

    const additionalFilters = [
      {
        condition: state.searchQuery,
        key: "searchQuery",
        value: state.searchQuery,
      },
      {
        condition: state.showExpiredHouses,
        key: "showExpiredHouses",
        value: "EPOnline verlopen",
      },
      {
        condition: state.monthsRange > 0,
        key: "monthsRange",
        value: `EPOnline verval binnen maand ${state.monthsRange}`,
      },
      {
        condition: state.filteredHousesInView?.length > 0,
        key: "filteredHousesInView",
        value: "Vastgoed in weergave",
      },
    ];

    additionalFilters.forEach(({ condition, key, value }) => {
      if (condition) {
        selectedFilters.push(selectedFilterButton(key, value));
      }
    });

    return selectedFilters;
  };

  return (
    <div>
      {totalAppliedFilters() > 0 && (
        <div className={styles.container}>
          <div className={styles.selectedTagFilters}>
            <h3>Toegepaste filters</h3>
            <span
              className={styles.selectedCount}
            >{` ${totalAppliedFilters()} `}</span>
            <InteractionTag
              appearance="brand"
              onClick={clearAllFilters}
              className={styles.text}
              size="medium"
            >
              <InteractionTagPrimary hasSecondaryAction>
                Verwijder alle filters
              </InteractionTagPrimary>
              <InteractionTagSecondary aria-label="remove" />
            </InteractionTag>
          </div>
          {getSelectedFilters().length > 0 && <div>{getSelectedFilters()}</div>}
        </div>
      )}
    </div>
  );
};

export default SelectedFilters;
