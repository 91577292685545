import KlantFilters from "./KlantFilters";
import MarketFilter from "./MarketFilter";
import ContractFilter from "./ContractFilter";
import FilterComponent from "./VasgoedFilter";
import EPOnlineFilters from "./EnergyLabelFilter";
import filterConfig from "config/FilterConfig";

const DynamicFiltersComponent = ({ state, setState, Labels }) => {
  const { epOnlineLabels, getHouseCountForFilter, handleCheckboxChange } = EPOnlineFilters({ state, setState });

  const updateState = (key, value) =>
    setState((prevState) => ({ ...prevState, [key]: value }));

  const sharedProps = {
    getHouseCountForFilter,
    ...state,
    ...Object.keys(filterConfig).reduce((acc, key) => {
      if (filterConfig[key].handler) {
        acc[filterConfig[key].handler] = handleCheckboxChange(
          filterConfig[key].stateKey
        );
      }
      return acc;
    }, {}),
    setSliderLabel: (value) => updateState("sliderLabel", value),
    setMonthsRange: (value) => updateState("monthsRange", value),
    epOnlineLabels,
    Labels,
  };

  if (state.showKlantContent) {
    return <KlantFilters {...sharedProps} />;
  } else if (state.showMarketContent) {
    return <MarketFilter {...sharedProps} />;
  } else if (state.showContractContent) {
    return <ContractFilter {...sharedProps} />;
  } else {
    return <FilterComponent {...sharedProps} />;
  }
};

export default DynamicFiltersComponent;
