import { useRef, useState } from "react";
import { Text, Image, makeStyles } from "@fluentui/react-components";
import ProfileCallout from "./ProfileCallout";
import IGraphUserProfile from "interfaces/graphUserProfile";
import { useTenant } from "context/TenantContext";

const useStyles = makeStyles({
  toolbar: {
    height: "50px",
    background: "transparent linear-gradient(177deg, #DD9700 0%, #EBBF44 100%) 0% 0% no-repeat padding-box",
    opacity: 1,
  },
  toolbarContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    height: "100%",
  },

  toolbarLeft: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    flexWrap: "nowrap",
  },
  toolbarRight: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    flexWrap: "nowrap",
  },

  toolbarItem: {
    borderRadius: 0,
    height: "48px",
    display: "flex",
    flex: "0 0 auto",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    fontWeight: "bold",
    fontSize: "16px",
    color: "#ffffff",
    userSelect: "none",
  },
});

const Toolbar = () => {
  const [profileCalloutVisible, setProfileCalloutVisible] = useState(false);
  const personaRef = useRef<HTMLDivElement>(null);
  const [profile] = useState<IGraphUserProfile>();
  const { tenant } = useTenant();
  const styles = useStyles();

  const handleHomePageClick = () => {
    window.location.reload();
  };

  return (
    <div className={styles.toolbar}>
      <div className={styles.toolbarContainer}>
        <div className={styles.toolbarLeft}>
          <div
            className={styles.toolbarItem}
            onClick={handleHomePageClick}
          >
            <Image
              src={`/radar.png`}
              style={{ marginLeft: "20px", marginTop: "5px", height: "32px" }}
            />
            <Text
              style={{ color: "white", marginLeft: "5px" }}
              size={500}
              weight="semibold"
            >
              Radar
              <Text
                style={{ color: "white", marginLeft: "5px" }}
                size={400}
              >
                | {tenant}
              </Text>
            </Text>
          </div>
        </div>
        <div className={styles.toolbarRight}>
          <ProfileCallout
            visible={profileCalloutVisible}
            onDismiss={() => setProfileCalloutVisible(false)}
            targetRef={personaRef}
            profile={profile}
          />
        </div>
      </div>
    </div>
  );
};

export default Toolbar;
