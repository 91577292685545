import React from "react";
import VasgoedLocationDetails from "./VasgoedLocationDetails";
import KlantLocationDetails from "./KlantLocationDetails";

interface LocationDetailsContainerProps {
  selectedVge: any;
  handleCloseLocationDetails: () => void;
  initializeStreetView: (vge: any) => void;
  isKlantContent: boolean;
}

const LocationDetailsContainer: React.FC<LocationDetailsContainerProps> = ({
  selectedVge,
  initializeStreetView,
  isKlantContent,
}) => {
  return (
    <>
      {isKlantContent ? (
        <KlantLocationDetails
          vge={selectedVge}
          initializeStreetView={initializeStreetView}
        />
      ) : (
        <VasgoedLocationDetails
          vge={selectedVge}
          initializeStreetView={initializeStreetView}
        />
      )}
    </>
  );
};

export default LocationDetailsContainer;
